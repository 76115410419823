import * as React from 'react';
import { Button, SimpleForm, useDataProvider, useRecordContext, useRedirect, useResourceContext } from "react-admin"
import { validate } from "../../utils/validations"
import BQEditableDataGrid from "../Generic/BQEditableDataGrid"
import { BQSection, BQToolbar } from "../Generic/BQUI"
import { BQCheckbox, BQSimpleForm, createLabelFromSource } from "../Generic/bq-form-components"
import { BQDropDown, BQInput } from "../Generic/bq-input"
import { useBQGetList } from "../Generic/hooks"
import { useFormContext } from 'react-hook-form';
import BQTestIterator from "../Generic/BQTestIterator"
import { useGetAppSettings } from '../../utils/globals';
import { BQFormExporter } from '../../utils/bq_form_export';
import { bqAuthData } from '../../utils/bq-auth';
import { InventoryCreate } from './inventory.component';

const defaultFieldsToWatch = ['pacakges']

export const getPackageName = (pkg, packageTypes) => {
    if (!pkg) {
        return null
    }
    const { packageType, packageSerialNumber, createdAt } = pkg
    const prefix = packageTypes?.[packageType]?.prefix
    const packageName = `${prefix}-${packageSerialNumber.toUpperCase()}${createdAt ? `-${parseInt(new Date(createdAt).getTime() / 10000)}` : ''}`
    return prefix ? packageName : packageSerialNumber
}

const bqExporter = new BQFormExporter()

export const ShipmentFormContainer = ({ formType, onFormContextChange, children, shipmentType, prepareForm, label, readOnly, ...props }) => {
    const redirect = useRedirect()
    const { shipmentTypes, itemTypes, packageTypes, conditions } = useGetAppSettings()
    return !!shipmentTypes && <BQSimpleForm
        onFormContextChange={onFormContextChange}
        prepareForm={({ formData, setValue }) => {
            shipmentType && !formData.shipmentType && setValue('shipmentType', shipmentType)
            prepareForm?.({ formData, setValue })
        }}
        toolbar={
            <BQToolbar
                {...props}
                hideButtons={readOnly}
                type={shipmentTypes[shipmentType]?.name || 'Shipment'}
                label={label}
                onBack={() => redirect(`/Inventories`)}
                exporterLabel="Export form"
                exporter={(formContext) => bqExporter.exportForm({ formType, data: formContext.getValues(), itemTypes, packageTypes, conditions })}
            />
        }
    >
        {children}
    </BQSimpleForm>
}

export const TemplatesSection = ({
    source,
    includeItems,
    onChange
}) => {
    const { itemTypes, packageTypes } = useGetAppSettings()


    const itemsSource = `${source ? `${source}.` : ''}items`
    const packgeTypeSource = `${source ? `${source}.` : ''}packageType`
    const idSource = `${source ? `${source}.` : ''}id`

    const formContext = useFormContext()
    const setTemplate = (templateKey) => {
        if (formContext.getValues(itemsSource)?.length && !confirm(`Do you want to change the template of the content? This cannot be undone!`)) {
            return
        }
        switch (templateKey) {
            case 'DEVICE':
            case 'ACCESSORIES':
                const newItems = itemTypes.filter(itemType =>
                    itemType.templates.some(t => t === templateKey && (!includeItems || includeItems(itemType))))
                    .map(itemType => ({
                        itemType: itemType.id,
                        ...(itemType.tracedBy === 'SERIAL_NUMBER' && { serialNumber: itemType.inputTemplate } || {}),
                        ...(itemType.tracedBy === 'RIR' && { rirNumber: itemType.inputTemplate } || {})
                    }))
                formContext.setValue(itemsSource, newItems, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
                formContext.setValue(packgeTypeSource, packageTypes[templateKey].id, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
                break;
            default:
                formContext.setValue(itemsSource, [], { shouldValidate: true, shouldDirty: true, shouldTouch: true })
                break;
        }
        formContext.setValue(idSource, undefined, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
        onChange()
    }

    return !!itemTypes && <table style={{ width: '100%' }}>
        <tr>
            {Object.keys(packageTypes).map(key => <td><Button onClick={() => setTemplate(key)}>{packageTypes[key].label} Template</Button></td>)}
        </tr>
    </table>
}

export const PackageListSection = ({
    isSender,
    record,
    formWatch,
    children,
    ...rest
}) => {
    const { isSuperAdmin, isInventoryAdmin } = bqAuthData
    const isSuperUser = isSuperAdmin || isInventoryAdmin
    return <BQTestIterator source="packages" {...rest}
        disableAdd={!rest?.canEditList && !isSuperUser}
        disableRemove={!rest?.canEditList && !isSuperUser}
    >

        {(pack, index) => <SinglePackageSection {...{
            index,
            pack,
            source: '',
            packageChildren: children,
            ...rest
        }} />}
    </BQTestIterator>
}

const getSelectedItemsIds = pack => pack?.items?.filter(item => item)?.map(item => item?.id)

export const SinglePackageSection = ({
    packageStatusFilter,
    itemStatusFilter,
    pack,
    source,
    canEditItems,
    canEditList,
    packageConditionVisible,
    canEditPackageCondition,
    itemStatusVisible,
    canEditItemStatus,
    mustEditItemStatus,
    highlightItems,
    includeItems,
    containerType,
    canEditCustomChildren,
    children,
    packageChildren
}) => {
    const { isSuperAdmin, isInventoryAdmin } = bqAuthData
    const isSuperUser = isSuperAdmin || isInventoryAdmin
    const { itemTypes, conditions, packageTypes } = useGetAppSettings()
    const [currentPackage, setCurrentPackage] = React.useState(pack)
    const sourcePrefix = `${source ? `${source}.` : ''}`
    const itemsSource = `${sourcePrefix}items`

    const resource = useResourceContext()
    const record = useRecordContext()

    const formContext = useFormContext()

    const additionalComponents = Array.isArray(children ?? packageChildren) ? children ?? packageChildren : [children ?? packageChildren]
    pack?.id && formContext.setValue(`${source}.id`, pack.id)
    const packageType = formContext.getValues(`${sourcePrefix}packageType`)
    const packageTypePrefix = packageTypes?.[packageType]?.prefix

    const [refreshToken, setRefreshToken] = React.useState(0)

    const localItemsList = useBQGetList({
        resource: 'inventoryItems',
        filter: {
            localItemsOnly: !!(resource.match(/dispens/i) && record?.id),
            //...(isSuperUser ? { status: itemStatusFilter?.id || 'READY_FOR_USE' } : {}),
            //packageId: pack?.id
        },
        refreshToken
    })

    const [usedListItems, setUsedListItems] = React.useState([])


    React.useEffect(() => {
        if (localItemsList?.data) {
            const packages = (formContext.getValues()?.packages || (formContext.getValues().package && [formContext.getValues().package]))
            const allSelectedItems = []
            packages?.forEach((pack) => getSelectedItemsIds(pack)?.forEach(id => id && allSelectedItems.push(id)))
            setUsedListItems(allSelectedItems)
        }
    }, [formContext])

    return !!itemTypes && !!localItemsList && <BQSection
        title={`Package content${pack?.packageSerialNumber ? ` (#${pack.packageSerialNumber})` : ''}`}
        fullWidth
        component={<table style={{ width: '100%', alignSelf: 'flex-start' }}>
            {(packageConditionVisible || canEditPackageCondition) && <BQDropDown source={`${sourcePrefix}condition`} choices={conditions} unsorted noPadding validate={isSuperUser ? undefined : validate} readOnly={!canEditPackageCondition} />}
            {highlightItems && <tr>
                <td style={{ textAlign: 'right' }}>
                    <span style={{ color: 'red' }}>{highlightItems.text}</span>
                </td>
            </tr>}
            {includeItems && <tr>
                <td style={{ textAlign: 'right' }}>
                    <span style={{ color: 'red' }}>{includeItems.text}</span>
                </td>
            </tr>}
        </table>
        }>
        <table>
            <tr>
                {((!pack?.id || canEditList) || isSuperUser) &&
                    <td style={{ width: '50%' }}>
                        <PackageSelector {...{
                            packageStatusFilter,
                            source: `${sourcePrefix}packageSerialNumber`,
                            label: `Package name:`,
                            packageTypes,
                            validate: validate,
                            onChange: ({ formContext, selection }) => {
                                setCurrentPackage(selection?.content)
                                formContext.setValue(`${source ? `${source}.` : ''}id`, selection?.content?.id)
                                formContext.setValue(`${source ? `${source}.` : ''}packageType`, selection?.content?.packageType)
                                const items = includeItems
                                    ? selection?.content?.items?.filter(item => !item.missing)?.filter(item => includeItems?.action({ item, itemTypes }))
                                    : selection?.content?.items?.filter(item => !item.missing)
                                formContext.setValue(`${sourcePrefix}items`, items)
                            }
                        }}
                        />
                    </td>
                }

                {((!pack?.id || canEditList) || isSuperUser) && <td style={{ width: '30%', }}><TemplatesSection {...{
                    pack,
                    source,
                    includeItems,
                    onChange: () => setCurrentPackage(null)
                }} />
                </td>}
                {isSuperUser && <td>
                    <InventoryCreate onItemCreated={(item) => {
                        localItemsList?.data.push(item)
                        formContext.setValue(itemsSource, [...formContext.getValues(itemsSource), item])
                        setRefreshToken(parseInt(Math.random() * 100))
                    }} />
                </td>}
            </tr>
        </table>

        <BQEditableDataGrid
            addLabel="Add item from inventory"
            source={itemsSource}
            noIterate
            {...{
                disableAdd: !canEditList && !isSuperUser,
                disableRemove: !canEditList && !isSuperUser,
                disableReorder: true,
                disableEdit: !canEditItems && !canEditItemStatus && !canEditCustomChildren && !isSuperUser,
                highlightRow: highlightItems ? item => highlightItems?.action({ item, itemTypes }) : null
            }}
        >
            {additionalComponents?.filter(item => item?.props && item?.props?.position?.toLowerCase() === 'start')}
            <BQDropDown
                source="itemType"
                label="Item type"
                readOnly={!canEditItems && !isSuperUser}
                choices={itemTypes}
                unsorted
                validate={validate}
                onChange={(data) => {
                    const { source: itemSource } = data
                    const idFieldSource = itemSource.replace(/.itemType/i, '.id')
                    formContext.setValue(idFieldSource, undefined, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
                    // setTimeout(() => {
                    //     document.getElementById(`${idFieldSource}_input`).focus()
                    // }, 1)
                }}
            />
            <ItemSelector {...{
                source: 'id',
                label: isSuperAdmin || isInventoryAdmin ? 'SN / RIR / Identifier (where applicable)' : 'Serial number / Identifier (where applicable)',
                noLabel: true,
                readOnly: !canEditItems && !isSuperUser,
                containerType,
                packageSource: source,
                itemsList: localItemsList,
                usedListItems
            }} />
            {(itemStatusVisible || canEditItemStatus) && <BQCheckbox source="damaged" label="Damaged" validate={mustEditItemStatus} onChange={onItemStateChanged} width={86} readOnly={!canEditItemStatus} />}
            {(itemStatusVisible || canEditItemStatus) && <BQCheckbox source="missing" label="Missing" validate={mustEditItemStatus} onChange={onItemStateChanged} width={86} readOnly={!canEditItemStatus} />}
            {(itemStatusVisible || canEditItemStatus) && <BQCheckbox source="returned" label="Returned" validate={mustEditItemStatus} onChange={onItemStateChanged} width={86} readOnly={!canEditItemStatus} />}
            {additionalComponents?.filter(item => item?.props && item?.props?.position?.toLowerCase() !== 'start')}
        </BQEditableDataGrid>
    </BQSection>
}

export const ItemSelector = ({ source, packageSource, readOnly, containerType, noLabel, isNewItem, itemsList, usedListItems }) => {
    const { isSuperAdmin, isInventoryAdmin } = bqAuthData
    const isSuperUser = isSuperAdmin || isInventoryAdmin
    const { itemTypes } = useGetAppSettings()
    const formContext = useFormContext()
    const recordSource = source.replace(/.id/g, '')
    const itemTypeSource = `${recordSource ? `${recordSource}.` : ''}.itemType`
    const itemData = itemsList?.data?.find(item => item.id === formContext.getValues(recordSource)?.id) || formContext.getValues(recordSource)
    const selectedItemType = formContext.getValues(itemTypeSource)
    const itemTypeData = itemTypes?.find(itemType => itemType.id === selectedItemType)
    const { tracedBy } = itemTypeData || {}
    const choices = itemsList?.data?.filter(item => item.itemType === selectedItemType)

    const [isChanged, setIsChanged] = React.useState(false)
    const [warning, setWarning] = React.useState(null)
    const dataProvider = useDataProvider();

    const validateItem = async (id) => {
        const isDirty = formContext.getFieldState(source)?.isDirty
        if (document.activeElement?.id === `${source}_input`) {
            return null
        }
        if (!id) {
            return 'This field is required'
        }


        setIsChanged(false)

        const packageId = formContext.getValues(`${packageSource ? `${packageSource}.` : ''}id`)
        const response = await dataProvider.customQuery('verifyInventoryItemById', {
            itemId: id,
            packageId,
            containerType
        })
        const responseObject = response?.data?.[`verifyInventoryItemById`]
        setWarning(responseObject.isItemValid && responseObject?.reason?.trim() ? responseObject.reason.trim() : null)
        const valiationResult = responseObject.isItemValid ? undefined : responseObject.reason
        return valiationResult
    }


    const currentItemIndex = parseInt(source.match(/(?<=\[)\d+(?=\])/g)?.pop())
    const itemsInPackage = formContext.getValues(`${packageSource ? `${packageSource}.` : ''}items`)
    const currentItemId = formContext.getValues(source)
    const isFieldDuplicated = currentItemId && itemsInPackage.some((item, i) => i != currentItemIndex && item.id === currentItemId)

    return readOnly ?
        <div>{getItemIdentifierString(itemData)}</div>
        : <BQDropDown
            isFieldDuplicated={isFieldDuplicated}
            placeholder='Select item from list'
            noLabel={noLabel}
            source={source}
            choices={choices}
            idsToHide={usedListItems}
            optionText={(item) => getItemIdentifierString(item)}
            allowFreeText={false}
            validate={validateItem}
            warning={warning}
            onChange={(e, selection) => {
                setIsChanged(true)
            }}
        />

}

const getItemIdentifierString = (item) => {
    if (!item) {
        return null
    }
    const { rirNumber, serialNumber, currentLocation } = item
    const { isSuperAdmin, isInventoryAdmin, ou } = bqAuthData
    const isSuperUser = isSuperAdmin || isInventoryAdmin
    return `${rirNumber ? `${rirNumber} (${serialNumber})` : serialNumber}${isSuperUser && currentLocation && currentLocation !== ou ? `${currentLocation === '-' ? ' in transit' : ` at ${currentLocation}`}` : ''}`
}

export const SerialOrRirInput = ({ source, packageSource, readOnly, containerType, noLabel, isNewItem }) => {
    const { isSuperAdmin, isInventoryAdmin } = bqAuthData
    const isSuperUser = isSuperAdmin || isInventoryAdmin
    const { itemTypes } = useGetAppSettings()
    const formContext = useFormContext()
    const recordSource = source.replace(/.serialNumber/g, '')
    const itemTypeSource = `${recordSource ? `${recordSource}.` : ''}.itemType`
    const selectedItemType = formContext.getValues(itemTypeSource)
    const itemTypeData = itemTypes?.find(itemType => itemType.id === selectedItemType)
    const { tracedBy } = itemTypeData || {}
    let fieldSource = `${recordSource}.serialNumber`
    switch (tracedBy) {
        case 'SERIAL_NUMBER':
            fieldSource = `${recordSource}.serialNumber`
            break
        case 'RIR':
            fieldSource = isSuperAdmin || isInventoryAdmin ? `${recordSource}.rirNumber` : null
            break
        default:
            fieldSource = !selectedItemType ? fieldSource : null
            break;
    }
    if (readOnly && !fieldSource) {
        fieldSource = `${recordSource}.serialNumber`
    }
    if (fieldSource?.startsWith('.')) {
        fieldSource = fieldSource.substring(1)
    }
    const label = createLabelFromSource(fieldSource)

    const [isChanged, setIsChanged] = React.useState(false)
    const [error, setError] = React.useState(null)
    const dataProvider = useDataProvider();


    const validateSerial = async (serialToCheck) => {
        const isDirty = formContext.getFieldState(fieldSource)?.isDirty
        if (document.activeElement?.id === `${fieldSource}_input`) {
            return null
        }
        if (fieldSource && !serialToCheck) {
            return 'This field is required'
        }

        if (serialToCheck && fieldSource?.includes('serialNumber')) {
            setIsChanged(false)

            const response = await dataProvider.customQuery('verifyInventoryItem', {
                serialNumber: serialToCheck,
                packageId: formContext.getValues(`${packageSource ? `${packageSource}.` : ''}id`),
                containerType,
                isNewItem
            })
            const responseObject = response?.data?.[`verifyInventoryItem`]
            const valiationResult = responseObject.isItemValid ? null : responseObject.reason
            setError(valiationResult)
            return valiationResult
        }
    }

    const checkForItemType = (newValue) => {
        itemTypes.find(itemType => {
            if (itemType.regex) {
                const regex = new RegExp(itemType.regex, 'i')
                if (regex.test(newValue)) {
                    formContext.setValue(`${recordSource}.itemType`, itemType.id)
                    formContext.trigger(`${recordSource}.itemType`)
                }
            }
        })
    }

    return !!fieldSource && (!readOnly || itemTypeData?.tracedBy === 'SERIAL_NUMBER') && <BQInput source={fieldSource}
        noLabel={noLabel}
        placeholder={label}
        readOnly={readOnly}
        onChange={(e) => {
            setIsChanged(true)
            setError(null)
            checkForItemType(e.target.value)
        }}
        validate={validateSerial} />
}

export const PackageSelector = (props) => {
    const formContext = useFormContext()
    const formValues = formContext.getValues()
    const isFieldDuplicated = formValues.packages?.some((pack1, i1) => formValues.packages?.some((pack2, i2) => i1 != i2 && pack1?.packageSerialNumber === pack2?.packageSerialNumber))
    const { packageStatusFilter } = props
    const packageFilterFunction = typeof packageStatusFilter === 'function' ? packageStatusFilter : undefined
    const listData = useBQGetList({
        resource: 'packages', meta: {
            ...(packageStatusFilter && !packageFilterFunction ? { status: packageStatusFilter.id } : {}),
            withItems: true
        }
    })
    const choices = listData.isLoading ? null : listData?.data
        ?.filter(item => !packageFilterFunction ? true : packageFilterFunction?.(item))
        ?.map((item) => ({
            id: item.packageSerialNumber,
            name: item.packageSerialNumber,
            content: item,
        })) ?? []
    return <table style={{ width: '100%' }}><BQDropDown
        {...props}
        allowFreeText
        choices={choices}
        validate={props.validate}
        isFieldDuplicated={isFieldDuplicated}
    /></table>
}

export const SubjectSelector = (props) => {
    const listData = useBQGetList({ resource: 'patients' })
    return <BQDropDown {...props} choices={listData?.data?.map((item) => ({ id: item.organizationalUnit, name: item.idNumber, content: item }))} validate={props.validate} />
}

export const onItemStateChanged = ({ value, source, formContext }) => {
    const lastIndexOfDot = source.lastIndexOf('.')
    const sourceName = source.substring(lastIndexOfDot + 1)
    const itemSourceName = source.substring(0, lastIndexOfDot)
    switch (sourceName) {
        case 'returned':
            if (value) {
                formContext.setValue(`${itemSourceName}.missing`, false, { shouldDirty: true, shouldValidate: true, shouldTouch: true })
                formContext.setValue(`${itemSourceName}.damaged`, false, { shouldDirty: true, shouldValidate: true, shouldTouch: true })
            }
            break;
        case 'missing':
            if (value) {
                formContext.setValue(`${itemSourceName}.returned`, false, { shouldDirty: true, shouldValidate: true, shouldTouch: true })
                formContext.setValue(`${itemSourceName}.damaged`, false, { shouldDirty: true, shouldValidate: true, shouldTouch: true })
            }
            break;
        case 'damaged':
            if (value) {
                formContext.setValue(`${itemSourceName}.missing`, false, { shouldDirty: true, shouldValidate: true, shouldTouch: true })
                formContext.setValue(`${itemSourceName}.returned`, true, { shouldDirty: true, shouldValidate: true, shouldTouch: true })
            }
            break;
    }

}

export const BQFileInput = ({ label, onFileSelected }) => {

    const handleUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            onFileSelected?.({
                fileName: file.name,
                base64String
            })
        }
    };

    const handleClick = () => {
        fileInput.current.click();
    };

    const fileInput = React.useRef(null);

    return (
        <>
            <Button type="button" onClick={handleClick}>{label || 'Upload CSV'}</Button>
            <input
                type="file"
                accept=".csv"
                ref={fileInput}
                onChange={handleUpload}
                style={{ display: 'none' }}
            />
        </>
    );
}