import { useContext, useEffect, useState } from "react"
import { DataProviderContext } from "react-admin"

export const recentRecepients = {}

const cachedData = {}

const persistantData = {}

export const clearObject = (obj) => Object.keys(obj).forEach(key => delete obj[key])

export const addToCache = (key, value) => {
    persistantData[key] = value
}

export const getFromCache = (key) => {
    return persistantData[key]
}

const setCachedData = async (key, action) => {
    cachedData[key] = cachedData[key] || (await action?.()) || []
    return cachedData[key]
}

export const getCachedMobileDeviceUsers = async (dataProvider) => {
    await setCachedData(
        'mobileDeviceUsers',
        async () => (await dataProvider.getList('mobileDeviceUsers', {
            pagination: { page: 1, perPage: 10000 }
        }))?.data
    )
    return cachedData['mobileDeviceUsers']
}

export const useGetAppSettings = () => {
    const dataContext = useContext(DataProviderContext);
    const [appSettings, setAppSettings] = useState({})

    useEffect(() => {
        setCachedData(
            'appSettings',
            async () => {
                const data = (await dataContext.getOne('BQInventoryConstantss', { id: '' }))?.data
                return JSON.parse(data?.data)
            }
        ).then(data => setAppSettings({
            ...data,
            getById: (objectName, id) => getById('appSettings', objectName, id)
        }))
    }, [])

    return appSettings
}

const getById = (collectionName, objectName, id) => {
    const objectData = cachedData[collectionName]?.[objectName]
    return objectData?.find(item => item.id === id)?.name
}

export const resetGlobals = () => {
    clearObject(recentRecepients)
    clearObject(cachedData)
}

