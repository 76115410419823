/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    Children,
    cloneElement,
    isValidElement,
} from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { getBasePath } from './BQUI';
import { getFromCache } from '../../utils/globals';

const useStyles = makeStyles(
    theme => ({
        root: {
            padding: 0,
            marginBottom: 0,
            '& > li:last-child': {
                borderBottom: 'none',
            },
        },
        line: {
            display: 'flex',
            listStyleType: 'none',
            borderBottom: `solid 1px ${theme.palette.divider}`,
            [theme.breakpoints.down('xs')]: { display: 'block' },
            '&.fade-enter': {
                opacity: 0.01,
                transform: 'translateX(100vw)',
            },
            '&.fade-enter-active': {
                opacity: 1,
                transform: 'translateX(0)',
                transition: 'all 500ms ease-in',
            },
            '&.fade-exit': {
                opacity: 1,
                transform: 'translateX(0)',
            },
            '&.fade-exit-active': {
                opacity: 0.01,
                transform: 'translateX(100vw)',
                transition: 'all 500ms ease-in',
            },
        },
        index: {
            width: '3em',
            paddingTop: '1em',
            [theme.breakpoints.down('sm')]: { display: 'none' },
        },
        form: { flex: 2 },
        action: {
            paddingTop: '0.5em',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'RaSimpleFormIterator' }
);

const BQAddButton = props => {
    const { label } = props
    const bqClasses = getFromCache('bqClasses');
    return <Button {...props} className={bqClasses.addButton} title={label || 'Add'}>{label}</Button>
}

const BQRemoveButton = props => {
    const { label } = props
    const bqClasses = getFromCache('bqClasses');
    return <Button {...props} className={bqClasses.removeButton} title={label || 'Remove'}>{label}</Button>
}

const BQTestIterator = props => {
    const {
        labelAdd,
        labelRemove,
        addButton = <BQAddButton label={labelAdd} />,
        removeButton = <BQRemoveButton abel={labelRemove} />,
        children,
        className,
        resource,
        source,
        disabled,
        disableAdd,
        disableRemove,
        variant,
        margin,
        noNumbering,
        bottomLine
    } = props;

    const formContext = useFormContext()
    const records = get(formContext.getValues(), source);

    const fieldArray = useFieldArray({
        name: props.source
    })

    const { append, remove } = fieldArray

    const classes = useStyles(props);

    const removeField = index => remove(index)

    const addField = () => append()

    const basePath = getBasePath()
    const parentSource = source
    return (
        <ul className={classNames(classes.root, className)} style={{ width: '100%' }}>
            {records?.map((member, i) => {
                const isFieldDuplicated = records.filter((item) => item === member).length > 1
                const components = children(member, i)
                return (
                    <li className={classes.line} style={{ borderBottom: 'none' }}>
                        {!noNumbering && <Typography
                            variant="body1"
                            className={classes.index}
                        >
                            {i + 1}
                        </Typography>}
                        <section className={classes.form}>
                            {Children.map(
                                components,
                                child => {
                                    if (!isValidElement(child)) {
                                        return null;
                                    }
                                    const {
                                        source,
                                    } = child.props;
                                    return (
                                        <div
                                            variant={variant}
                                            margin={margin}
                                        >{
                                                setChild(child, i, {
                                                    parentSource,
                                                    isFieldDuplicated,
                                                    basePath,
                                                    resource: resource,
                                                    label:
                                                        typeof child.props
                                                            .label ===
                                                            'undefined'
                                                            ? source
                                                                ? `resources.${resource}.fields.${source}`
                                                                : undefined
                                                            : child.props.label,
                                                    disabled,
                                                })
                                            }
                                        </div>
                                    );
                                }
                            )}
                            {bottomLine && <hr style={{ width: '100%' }} />}
                        </section>
                        {!disabled && !disableRemove &&
                            (
                                <span className={classes.action}>
                                    {cloneElement(removeButton, {
                                        onClick: () => removeField(i),
                                        className: classNames(
                                            'button-remove',
                                            `button-remove-${source}-${i}`
                                        ),
                                    })}
                                </span>
                            )}
                    </li>
                )
            })}

            {!disabled && !disableAdd && <li className={classes.line}>
                <span className={classes.action}>
                    {cloneElement(addButton, {
                        onClick: () => addField(),
                        className: classNames(
                            'button-add',
                            `button-add-${source}`
                        ),
                    })}
                </span>
            </li>}
        </ul>
    );
};

const setChild = (child, i, customProps) => {
    const { source, children } = child.props
    const { parentSource } = customProps
    const childSource = `${parentSource}[${i}]${source ? `.${source}` : ''}`
    return cloneElement(child, {
        ...child.props,
        ...customProps,
        source: childSource,
        label: child.props.label || childSource,
        children: child.props.noIterate ? children : children && Children.map(children, child => {
            if (!isValidElement(child)) {
                return null;
            }
            return setChild(child, i, customProps)
        })
    })
}

BQTestIterator.defaultProps = {
    disableAdd: false,
    disableRemove: false,
};

BQTestIterator.propTypes = {
    defaultValue: PropTypes.any,
    addButton: PropTypes.element,
    removeButton: PropTypes.element,
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    // @ts-ignore
    fields: PropTypes.object,
    // @ts-ignore
    record: PropTypes.object,
    source: PropTypes.string,
    resource: PropTypes.string,
    translate: PropTypes.func,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    TransitionProps: PropTypes.shape({}),
};

export default BQTestIterator;
