import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BQAuthenticator } from './components/auth/BQAuthenticator';
import { resetSessionTimeout } from './utils/constants';
import BQDataProvider from './BQDataProvider';
import { useBQStyles } from './themes';
import { addToCache, getFromCache } from './utils/globals';
import { clearBQAuthData } from './utils/bq-auth';
import { createRoot } from 'react-dom/client';
import { getCookieValue } from './utils/textUtils';

if (window.location.href.match(/(?<=(\?|&)).*?(filter|filters).*?\=.*?(&|$)/ig)) {
    window.location = window.location.href.replace(/(?<=(\?|&)).*?(filter|filters).*?\=.*?(&|$)/ig, '')
}

const baseError = console.error;
console.error = function filterWarnings(msg) {
    const supressedWarnings = ['Warning:', 'Material-UI'];
    try {
        if (!supressedWarnings.some(entry => msg && msg.includes(entry))) {
            baseError.apply(console, arguments);
        } else {
            console.warn(msg);
        }
    }
    catch {
        baseError.apply(console, arguments);
    }
};


const MainComponent = () => {
    if (!getFromCache('bqClasses')) {
        addToCache('bqClasses', useBQStyles())
    }
    return <ContentComponent />
}

const ContentComponent = () => {
    const [token, setToken] = React.useState()

    React.useEffect(() => {
        window.addEventListener('message', (event) => {
            if (!getCookieValue('accessToken') && event.data.type === 'bqAuthentication') {
                const { accessToken, idToken } = event?.data?.payload
                document.cookie = `accessToken=${accessToken}; Secure; SameSite=Strict; path=/;`;
                document.cookie = `idToken=${idToken}; Secure; SameSite=Strict; path=/;`;
                !token && setTimeout(() => setToken(accessToken), 100)
            }
        });
    }, [])

    return <BQAuthenticator accessToken={token}>
        <App hideDrawer={!!token} />
    </BQAuthenticator>
}

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <MainComponent />
    </React.StrictMode>
);

window.onbeforeunload = function (e) {
    clearBQAuthData()
    sessionStorage.setItem('reload', new Date().getTime())
    BQDataProvider.saveState()
};

window.onmousemove = function (e) {
    resetSessionTimeout()
}

window.onkeydown = function (e) {
    resetSessionTimeout()
}

resetSessionTimeout()