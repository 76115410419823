export const createRefreshToken = () => (parseInt(Math.random() * 100)).toString()
export const prepareItemInPackage = (item, index) => {
    const inputKeys = [
        'id',
        'index',
        'itemType',
        'quantity',
        'currentLocation',
        'missing',
        'returned',
        'refurbished',
        'damaged',
        'cleaned',
        'status'
    ]
    const itemKeys = Object.keys(item)
    itemKeys.forEach(key => {
        if (!inputKeys.includes(key)) {
            delete item[key]
        }
    })
    return {
        ...item,
        index
    }
}

export const handleLocationValue = (location) => location === '-' ? 'In transit' : location


export const filterItemsForCleaning = {
    text: 'Note: Only applicable items are shown',
    action: ({ item, itemTypes }) => {
        const itemsToClean = itemTypes.filter(itemType => itemType.cleanBySite)
        return itemsToClean.some(itemType => (item.itemType || item.name) === itemType.id)
    }
}

export const highlightObject_cleaning = {
    text: `Note: Some items may need to be removed for cleaning and will be highlighted. Remember to keep them and fill a cleaning form`,
    action: ({ item, itemTypes }) => {
        const itemTypeData = itemTypes?.find(itemType => itemType.id === item?.itemType)
        return itemTypeData?.cleanBySite
    }
}