/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
import { LinearProgress, useChoicesContext, useRecordContext } from 'react-admin';
import { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { createLabelFromSource, getLabelValue } from './bq-form-components';
import ClearIcon from '@material-ui/icons/Clear';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import flags from 'react-phone-number-input/flags';
import { formDate, getDateTime, getValueByPath, isNullOrEmpty, isNullOrUndefined } from '../../utils/textUtils';
import { getFromCache } from '../../utils/globals';

export const BQInput = (props) => {
    const {
        source = '',
        nullable,
        min,
        max,
        validate,
        onChange: baseOnChange,
        defaultValue,
        type,
        multiline,
    } = props;

    const formContext = useFormContext()
    const { control, formState, getValues, setValue } = formContext;
    const [isChanged, setIsChanged] = useState(isNullOrEmpty(false));

    useEffect(() => {
        if (isChanged) {
            return
        }
        const currentValue = getValues(source);
        if (isNullOrEmpty(currentValue)) {
            setValue(source, defaultValue, { shouldTouch: false, shouldDirty: false });
        }
    }, [defaultValue, formContext, source]);

    const localValidate = (value) => {
        if (!validate || (nullable && (value == null || value === '') && value !== 0)) {
            return null;
        }

        if (Array.isArray(validate)) {
            for (const validateFunction of validate) {
                const validationText = validateFunction(value, min, max);
                if (validationText) {
                    return validationText;
                }
            }
        } else {
            return validate(value, min, max);
        }
        return null;
    };

    return (
        <Controller
            name={source}
            control={control}
            rules={{ validate: localValidate }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                const inputValue =
                    type === 'date' ? (value ? formDate(new Date(value)) : undefined) : value;

                const handleChange = (val) => {
                    if (!isChanged) {
                        setIsChanged(true)
                    }
                    formContext.clearErrors(source);
                    onChange(val, { shouldValidate: true });
                    baseOnChange?.(val, source, control);
                };

                const handleBlur = (e) => {
                    onBlur(e, { shouldValidate: true });
                    formContext.trigger(source);
                };

                const handleKeyDown = (e) => {
                    if (!multiline && e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                        e.target.blur();
                    }
                };

                return (
                    <BQInputComponent
                        {...props}
                        value={inputValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        error={error}
                    />
                );
            }}
        />
    );
};

const BQInputComponent = (props) => {
    const bqClasses = getFromCache('bqClasses');
    const {
        value,
        nullable,
        type,
        validate,
        minHeight,
        multiline,
        id,
        visible,
        source,
        label,
        noLabel,
        readOnly,
        style,
        placeholder,
        maxLength,
        duplicates,
        error,
        noErrorMessage,
        ...rest
    } = props;
    const { message: errorMessage } = error || {};

    let duplicateMessage = duplicates?.itemName && duplicates?.validate(duplicates.itemName || 'Item', source, duplicates.duplicates);
    const inputClasses = [];
    inputClasses.push(bqClasses.bqInputValue);
    if (errorMessage || duplicateMessage) {
        inputClasses.push(bqClasses.bqInputValueError);
    }

    const idClassName = `${id, source || label}`;
    const inputClassName = inputClasses.reduce((acc, item) => `${acc} ${item}`);
    return (
        <>
            <tr className={bqClasses.textInputContainer} style={visible === false ? { display: 'none', height: 0 } : {}} title="">
                {!noLabel && (
                    <td className={bqClasses.inputLabel}>
                        <span className={`${idClassName}_label`}>
                            {label || createLabelFromSource(source)} {validate && !nullable ? ' *' : ''}
                        </span>
                    </td>
                )}
                <td className={bqClasses.textInput}>
                    <span style={{ ...(style || {}), minWidth: '100%' }}>
                        {readOnly ? (
                            <div id={`${idClassName}_readOnly`} className={bqClasses.bqInputValueReadOnly}>
                                {type === 'date' && value ? getDateTime(new Date(value), true) : value}
                            </div>
                        ) : multiline ? (
                            <textarea
                                {...rest}
                                id={`${idClassName}_input`}
                                className={inputClassName}
                                {...{ placeholder, maxLength }}
                                value={value}
                                autoComplete="off"
                                style={{ height: `${minHeight || 72}px`, lineHeight: '18px', overflow: 'auto' }}
                            />
                        ) : type === 'phoneNumber' ? (
                            <PhoneInput
                                className={inputClassName}
                                placeholder={`${placeholder || 'Enter phone number'}`}
                                {...rest}
                                value={value}
                                style={{ maxHeight: '26px' }}
                                flags={flags}
                                international
                            />
                        ) : (
                            <input
                                {...rest}
                                type={type}
                                autoComplete="off"
                                id={`${idClassName}_input`}
                                className={inputClassName}
                                value={value}
                                {...{ placeholder, maxLength }}
                            />
                        )}
                    </span>
                </td>
            </tr>
            {!noErrorMessage && (
                <ErrorSection id={`${idClassName}_error`} message={duplicateMessage || errorMessage} visible={visible} noLabel={noLabel} />
            )}
        </>
    );
};

export const useInputData = (props) => {
    const { source, ...rest } = props || {}
    const formContext = useFormContext()
    const watcher = source && formContext.watch(source)
    const register = source ? formContext.register(source, { ...rest }) : {}

    const [field, setField] = useState({})

    useEffect(() => {
        const fieldState = formContext.getFieldState(source)
        const value = watcher
        const sourceError = source && getValueByPath(formContext.formState.errors, source)
        const errorMessage = sourceError ? (sourceError?.message?.match(/@@react-admin@@"(.*?)"/)?.[1] || sourceError?.message) || props?.validate?.(watcher) : null
        source && setField({
            ...register,
            value,
            errorMessage,
            ...fieldState,
        })
    }, [watcher, formContext.formState])

    return field
}

let positionTimeout = null

const fixCursorPosition = ({ event }) => {
    const { selectionStart, selectionEnd, scrollTop } = event.target;
    if (selectionEnd < event.target.value.length) {
        event.target.readOnly = true
        clearTimeout(positionTimeout)
        positionTimeout = setTimeout(() => {
            event.target.scrollTop = scrollTop
            event.target.selectionStart = selectionStart
            event.target.selectionEnd = selectionEnd
            event.target.readOnly = false
        }, 5)
    }
}

export const BQDropDown = (props) => {
    const {
        id,
        visible,
        source,
        style,
        label,
        placeholder,
        validate,
        duplicates,
        optionText,
        optionValue,
        choices: propsChoices,
        loading,
        disableValue,
        defaultValueSource,
        unsorted,
        readOnly,
        minWidth,
        borderless,
        noLabel,
        onChange: baseOnChange,
        noErrorMessage,
        isFieldDuplicated,
        allowDuplicates,
        useNameAsValue,
        allowFreeText,
        noPadding
    } = props;

    const bqClasses = getFromCache('bqClasses')

    const localValidate = (value) => {
        if (isFieldDuplicated && !allowDuplicates) {
            return 'Duplicate value'
        }
        return validate?.(value)
    }

    const defaultValue = defaultValueSource && useInputData({ ...props, validate: localValidate, source: defaultValueSource })?.value || props.defaultValue

    const field = useInputData({ ...props, validate: localValidate })
    const [isOpen, setIsOpen] = useState()
    const [filterValue, setFilterValue] = useState()
    const [filteredChoices, setFilteredChoices] = useState()
    const [dropdownPositionTop, setDropdownPositionTop] = useState()
    const [selectedIndex, setSelectedIndex] = useState()
    const [localValue, setLocalValue] = useState()
    const [inputProps, setInputProps] = useState({ value: filterValue ?? field.value })

    const formContext = useFormContext();
    const choiceContext = useChoicesContext()
    const choices = propsChoices || choiceContext?.allChoices

    const idField = optionValue || 'id'
    const idClassName = `${id || source || label}`

    const { errorMessage } = field || {}
    let duplicateMessage = duplicates && duplicates.validate(duplicates.itemName || 'Item', source, duplicates.duplicates)

    const inputClasses = []
    inputClasses.push(borderless ? bqClasses.bqInputValueBorderless : bqClasses.bqInputValue)
    if (errorMessage || duplicateMessage) {
        inputClasses.push(bqClasses.bqInputValueError)
    }
    const inputClassName = inputClasses.reduce((acc, item) => `${acc} ${item}`)

    const createInputProps = () => {
        const formValue = getValueByPath(formContext.getValues(), source)
        const selectedValue = source ? (isNullOrEmpty(formValue) && !field.isTouched ? defaultValue : formValue) : defaultValue
        const selectedItem = !isNullOrEmpty(selectedValue) ? choices?.find(item => item[idField] == selectedValue) : null
        const selectedDisplayValue = selectedItem && (optionText && optionText(selectedItem) || selectedItem?.name) || (selectedValue !== undefined && selectedValue !== null ? (typeof selectedValue !== 'object' && selectedValue) : undefined)
        setInputProps({
            value: !isNullOrUndefined(filterValue) ? filterValue : !isNullOrUndefined(selectedDisplayValue) ? selectedDisplayValue : ''
        })
    }

    useEffect(() => {
        if (field.isTouched) {
            formContext.trigger(source)
        }
    }, [isFieldDuplicated])

    const record = useRecordContext()
    useEffect(() => {
        if (!isNullOrEmpty(props.defaultValue) && !field.isTouched && !record) {
            formContext.setValue(source, props.defaultValue, { shouldTouch: true, shouldDirty: false })
        }
        createInputProps()
    }, [field, choices])

    useEffect(() => {
        if (filterValue) {
            const lowerCasedFilterValue = filterValue.toLowerCase()
            setFilteredChoices([...choices?.map(item => {
                const itemValue = optionText && optionText(item) || item.name
                return { ...item, indexOfFilter: itemValue?.toLowerCase()?.indexOf(lowerCasedFilterValue) }
            }).filter(item => item.indexOfFilter !== -1)])
        } else {
            setFilteredChoices(null)
        }
        createInputProps()
    }, [filterValue])

    const localOnBlur = (e) => {
        setFilterValue(null)
        if (allowFreeText) {
            const value = e.target.value
            formContext.setValue(source, value, { shouldTouch: true, shouldDirty: true })
        }
        source && formContext.trigger(source)
    }

    const onLocalInput = (event) => {
        fixCursorPosition({ event })
    }

    const localOnChange = (event) => {
        if (!isOpen) {
            setIsOpen(true)
        }
        setFilterValue(event?.nativeEvent?.target?.value)
    }

    const onSelect = (selection, event) => {
        source && errorMessage && formContext.clearErrors(source)
        setFilterValue(null)
        let newValue = null
        if (!isNullOrUndefined(selection?.[idField])) {
            newValue = useNameAsValue ? selection.name : selection[idField]
        } else {
            newValue = allowFreeText && event.target.value
        }
        if (source) {
            formContext.setValue(source, newValue, { shouldTouch: true, shouldDirty: true });
        } else {
            setLocalValue(optionText && optionText(selection) || selection?.name)
        }
        baseOnChange?.({ event, selection, formContext, source })
    }

    let choicesToDisplay = !choiceContext?.isLoading && (filteredChoices || choices) ? [...(filteredChoices || choices)] : null
    if (!unsorted) {
        choicesToDisplay?.sort((a, b) => {
            const sortByIndex = (a.indexOfFilter || 0) - (b.indexOfFilter || 0)
            if (sortByIndex !== 0) {
                return sortByIndex
            }
            const numberCompare = a.name - b.name;
            if (numberCompare !== 0) {
                return numberCompare
            }

            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1
            } else {
                return 0;
            }
        })
    }

    const onKeyDown = (e) => {
        source && errorMessage && formContext.clearErrors(source)
        let _selectedIndex = selectedIndex !== undefined ? selectedIndex : -1
        switch (e.code) {
            case 'ArrowDown':
                _selectedIndex += 1
                break;
            case 'ArrowUp':
                _selectedIndex -= 1
                break;
            case 'Enter':
                e.stopPropagation()
                e.preventDefault()
                setIsOpen(false)
                onSelect(choicesToDisplay[_selectedIndex], e)
                break;
        }
        _selectedIndex = Math.min((choicesToDisplay?.length || 0) - 1, Math.max(0, _selectedIndex))

        setSelectedIndex(_selectedIndex)
    }

    const onOpenEvent = (e) => {
        const rect = e.target.getBoundingClientRect()
        setDropdownPositionTop(window.innerHeight - rect.y - rect.height < 256)
        setIsOpen(true)
    }

    const labelToDisplay = (label !== undefined && getLabelValue(props) || createLabelFromSource(source) || label)?.trim()
    return <>
        <tr className={bqClasses.textInputContainer} style={visible === false ? { display: 'none', height: 0 } : {}} title="">
            {
                labelToDisplay && !noLabel && <td className={bqClasses.inputLabel}>
                    <span className={`${idClassName}_label`}>
                        {labelToDisplay} {validate ? ' *' : ''}
                    </span>
                </td>
            }
            <td className={bqClasses.textInput} style={{ ...(noPadding ? { padding: 0 } : {}) }}>
                {
                    (choicesToDisplay) ?
                        readOnly ?
                            <div id={`${idClassName}_readOnly`} {...inputProps} className={bqClasses.bqInputValueReadOnly} style={style}>{inputProps.value}</div>
                            :
                            <span style={{ ...(style || {}), minWidth: '100%' }}>
                                <div
                                    style={{ position: 'relative' }}
                                    onBlur={(e) => {
                                        setIsOpen(false)
                                        localOnBlur(e)
                                    }}
                                    onKeyDown={onKeyDown}>
                                    <input
                                        id={`${idClassName}_input`}
                                        className={inputClassName}
                                        placeholder={placeholder}
                                        {...inputProps}
                                        style={minWidth ? { minWidth: `${minWidth}px` } : {}} {...(localValue && { value: localValue })}
                                        autocomplete="off"
                                        onFocus={onOpenEvent}
                                        onClick={onOpenEvent}
                                        onChange={localOnChange}
                                        onInput={onLocalInput}
                                        onBlur={null} />
                                    {
                                        !borderless && <ClearIcon id={`${idClassName}_clear`} className={bqClasses.dropdownClearIcon} onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            setFilteredChoices(null)
                                            if (source) {
                                                formContext.setValue(source, null, { shouldTouch: true, shouldDirty: true })
                                                validate && formContext.setError(source, { message: validate?.(null) })
                                            } else {
                                                setLocalValue(null)
                                            }
                                            baseOnChange?.({ event: e, selection: {}, formContext, source })
                                        }} />
                                    }
                                    {isOpen && <BQDropDownItems idClassName={idClassName} dropdownPositionTop={dropdownPositionTop} selectedIndex={selectedIndex} choices={choicesToDisplay} optionText={optionText} optionValue={optionValue} setIsOpen={setIsOpen} isOpen={isOpen} defaultValue={defaultValue} onSelect={onSelect} />}
                                </div>
                            </span>
                        : <div style={{ paddingBottom: '12px' }}><LinearProgress id={`${idClassName}_progress`} style={{ marginTop: '12px' }} /></div>
                }
            </td>
        </tr>

        {!borderless && !noErrorMessage && <ErrorSection {...props} id={`${idClassName}_error`} message={duplicateMessage || errorMessage} visible={visible} noLabel={noLabel} />}
    </>
}

const BQDropDownItems = (props) => {
    const { idClassName, choices, selectedIndex, isOpen, setIsOpen, optionText, optionValue, defaultValue, onSelect, dropdownPositionTop } = props;
    const bqClasses = getFromCache('bqClasses');
    return (
        <div
            id={`${idClassName}-listbox`}
            className={bqClasses.dropdownContainer}
            style={dropdownPositionTop ? { bottom: '36px' } : { top: '43px' }}
        >
            {choices.map((item, index) => {
                const value = optionText ? optionText(item) : item.name;
                return (
                    <div
                        key={value}
                        id={`option_${value}`}
                        className={bqClasses.dropdownItem}
                        style={index === selectedIndex ? { backgroundColor: 'rgb(240, 240, 240)' } : null}
                        onMouseDown={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onClick={(e) => {
                            setIsOpen(!isOpen);
                            onSelect?.(item, e);
                        }}
                    >
                        {value}
                        {item.id == defaultValue && <div className={bqClasses.dropdownDefaultIcon}>default</div>}
                    </div>
                );
            })}
        </div>
    );
};

const ErrorSection = (props) => {
    const { id, message, noLabel } = props;
    return (
        <tr>
            {message && !noLabel && <td></td>}
            {message && (
                <td id={id} style={{ color: 'red', fontSize: '12px', marginBottom: 0 }}>
                    {message}
                </td>
            )}
        </tr>
    );
};