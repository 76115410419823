/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCognitoUser = /* GraphQL */ `
  query GetCognitoUser($id: String!) {
    getCognitoUser(id: $id) {
      id
      email
      phoneNumber
      bqUsername
      role
      organizationalUnitId
      organizationalUnitName
      createDate
      lastModifiedDate
      isActive
      reset_password
      customAction
      tempPassword
      __typename
    }
  }
`;
export const listOrganizationalUnits = /* GraphQL */ `
  query ListOrganizationalUnits(
    $filter: String
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationalUnits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        facilityName
        organizationalUnit
        organizationalUnit_scan
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients($filter: String, $limit: Int, $nextToken: String) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        idNumber
        organizationalUnitId
        organizationalUnit
        numberOfTreatments
        recentTreatmentDate
        participationConcluded
        deletedAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listDispensedPatients = /* GraphQL */ `
  query ListDispensedPatients(
    $filter: String
    $limit: Int
    $nextToken: String
  ) {
    listDispensedPatients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        patientOrganizationalUnit
        dispenseDate
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const getDataByPatient = /* GraphQL */ `
  query GetDataByPatient($id: ID!) {
    getDataByPatient(id: $id) {
      patientOrganizationalUnit
      inventoryItems {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      __typename
    }
  }
`;
export const getBQInventoryConstants = /* GraphQL */ `
  query GetBQInventoryConstants($id: ID!) {
    getBQInventoryConstants(id: $id) {
      id
      data
      __typename
    }
  }
`;
export const listShipments = /* GraphQL */ `
  query ListShipments($filter: String, $limit: Int, $nextToken: String) {
    listShipments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        packages {
          id
          packageType
          packageSerialNumber
          condition
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          __typename
        }
        status
        createdAt
        deletedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const getShipment = /* GraphQL */ `
  query GetShipment($id: ID!) {
    getShipment(id: $id) {
      id
      shipmentType
      senderUserId
      receiverUserId
      senderOU
      destinationOU
      trackingNumber
      shipmentDate
      deliveryDate
      shipmentComments
      deliveryComments
      packages {
        id
        packageType
        packageSerialNumber
        condition
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        __typename
      }
      status
      createdAt
      deletedAt
      __typename
    }
  }
`;
export const listDispenses = /* GraphQL */ `
  query ListDispenses($filter: String, $limit: Int, $nextToken: String) {
    listDispenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        senderUserId
        returnUserId
        senderOU
        patientIdNumber
        currentLocation
        dispenseDate
        dispenseComments
        returnDate
        returnComments
        package {
          id
          packageType
          packageSerialNumber
          condition
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          __typename
        }
        status
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const getDispense = /* GraphQL */ `
  query GetDispense($id: ID!) {
    getDispense(id: $id) {
      id
      senderUserId
      returnUserId
      senderOU
      patientIdNumber
      currentLocation
      dispenseDate
      dispenseComments
      returnDate
      returnComments
      package {
        id
        packageType
        packageSerialNumber
        condition
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        __typename
      }
      status
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCleanings = /* GraphQL */ `
  query ListCleanings($filter: String, $limit: Int, $nextToken: String) {
    listCleanings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cleaningUserId
        currentLocation
        cleaningMaterial
        cleaningMaterialExpirationDate
        cleaningDate
        comments
        package {
          id
          packageType
          packageSerialNumber
          condition
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const getCleaning = /* GraphQL */ `
  query GetCleaning($id: ID!) {
    getCleaning(id: $id) {
      id
      cleaningUserId
      currentLocation
      cleaningMaterial
      cleaningMaterialExpirationDate
      cleaningDate
      comments
      package {
        id
        packageType
        packageSerialNumber
        condition
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPackages = /* GraphQL */ `
  query ListPackages($filter: String, $limit: Int, $nextToken: String) {
    listPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        containerType_Id
        packageType
        packageSerialNumber
        currentLocation
        currentLocation_scan
        condition
        status
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const getPackage = /* GraphQL */ `
  query GetPackage($id: ID!) {
    getPackage(id: $id) {
      id
      containerType_Id
      packageType
      packageSerialNumber
      currentLocation
      currentLocation_scan
      condition
      status
      items {
        id
        index
        serialNumber
        rirNumber
        itemType
        quantity
        currentLocation
        missing
        returned
        refurbished
        damaged
        cleaned
        status
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      log {
        serialNumber
        containerType_Id
        timestamp
        userId
        location
        location_scan
        action
        status
        id
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listInventoryItems = /* GraphQL */ `
  query ListInventoryItems($filter: String, $limit: Int, $nextToken: String) {
    listInventoryItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const getInventoryItem = /* GraphQL */ `
  query GetInventoryItem($id: ID!) {
    getInventoryItem(id: $id) {
      id
      serialNumber
      rirNumber
      containerType_Id
      itemType
      currentLocation
      currentLocation_scan
      patientIdNumber
      patientIdNumber_scan
      status
      status_itemType
      latestAction
      dispensedAt
      updatedAt
      deletedAt
      log {
        serialNumber
        containerType_Id
        timestamp
        userId
        location
        location_scan
        action
        status
        id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      __typename
    }
  }
`;
export const verifyInventoryItem = /* GraphQL */ `
  query VerifyInventoryItem(
    $serialNumber: String
    $packageId: String
    $containerType: String
    $isNewItem: Boolean
  ) {
    verifyInventoryItem(
      serialNumber: $serialNumber
      packageId: $packageId
      containerType: $containerType
      isNewItem: $isNewItem
    ) {
      isItemValid
      reason
      __typename
    }
  }
`;
export const verifyInventoryItemById = /* GraphQL */ `
  query VerifyInventoryItemById(
    $itemId: String
    $packageId: String
    $containerType: String
    $isNewItem: Boolean
  ) {
    verifyInventoryItemById(
      itemId: $itemId
      packageId: $packageId
      containerType: $containerType
      isNewItem: $isNewItem
    ) {
      isItemValid
      reason
      __typename
    }
  }
`;
export const listAuditTrails = /* GraphQL */ `
  query ListAuditTrails($filter: String, $limit: Int, $nextToken: String) {
    listAuditTrails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        dateISO
        userName
        userId
        affectedUserId
        eventName
        eventType
        description
        tableName
        dataBefore
        dataAfter
        dataDiff
        sourceOfChange
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const listAuditTrailEventNames = /* GraphQL */ `
  query ListAuditTrailEventNames(
    $filter: String
    $limit: Int
    $nextToken: String
  ) {
    listAuditTrailEventNames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const shipmentsByShipmentTypeAndShipmentDate = /* GraphQL */ `
  query ShipmentsByShipmentTypeAndShipmentDate(
    $shipmentType: String!
    $shipmentDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsByShipmentTypeAndShipmentDate(
      shipmentType: $shipmentType
      shipmentDate: $shipmentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        senderOU_destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        status
        packages {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsBySenderOUAndShipmentDate = /* GraphQL */ `
  query ShipmentsBySenderOUAndShipmentDate(
    $senderOU: String!
    $shipmentDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsBySenderOUAndShipmentDate(
      senderOU: $senderOU
      shipmentDate: $shipmentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        senderOU_destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        status
        packages {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsBySenderOUAndDeliveryDate = /* GraphQL */ `
  query ShipmentsBySenderOUAndDeliveryDate(
    $senderOU: String!
    $deliveryDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsBySenderOUAndDeliveryDate(
      senderOU: $senderOU
      deliveryDate: $deliveryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        senderOU_destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        status
        packages {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsByDestinationOUAndShipmentDate = /* GraphQL */ `
  query ShipmentsByDestinationOUAndShipmentDate(
    $destinationOU: String!
    $shipmentDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsByDestinationOUAndShipmentDate(
      destinationOU: $destinationOU
      shipmentDate: $shipmentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        senderOU_destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        status
        packages {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsByDestinationOUAndDeliveryDate = /* GraphQL */ `
  query ShipmentsByDestinationOUAndDeliveryDate(
    $destinationOU: String!
    $deliveryDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsByDestinationOUAndDeliveryDate(
      destinationOU: $destinationOU
      deliveryDate: $deliveryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        senderOU_destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        status
        packages {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsBySenderOU_destinationOUAndShipmentDate = /* GraphQL */ `
  query ShipmentsBySenderOU_destinationOUAndShipmentDate(
    $senderOU_destinationOU: String!
    $shipmentDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsBySenderOU_destinationOUAndShipmentDate(
      senderOU_destinationOU: $senderOU_destinationOU
      shipmentDate: $shipmentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        senderOU_destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        status
        packages {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsBySenderOU_destinationOUAndDeliveryDate = /* GraphQL */ `
  query ShipmentsBySenderOU_destinationOUAndDeliveryDate(
    $senderOU_destinationOU: String!
    $deliveryDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsBySenderOU_destinationOUAndDeliveryDate(
      senderOU_destinationOU: $senderOU_destinationOU
      deliveryDate: $deliveryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        senderOU_destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        status
        packages {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsByStatusAndShipmentDate = /* GraphQL */ `
  query ShipmentsByStatusAndShipmentDate(
    $status: String!
    $shipmentDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsByStatusAndShipmentDate(
      status: $status
      shipmentDate: $shipmentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        senderOU_destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        status
        packages {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsByDeletedAtAndShipmentDate = /* GraphQL */ `
  query ShipmentsByDeletedAtAndShipmentDate(
    $deletedAt: Int!
    $shipmentDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsByDeletedAtAndShipmentDate(
      deletedAt: $deletedAt
      shipmentDate: $shipmentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        senderOU_destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        status
        packages {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsByDeletedAtAndDeliveryDate = /* GraphQL */ `
  query ShipmentsByDeletedAtAndDeliveryDate(
    $deletedAt: Int!
    $deliveryDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsByDeletedAtAndDeliveryDate(
      deletedAt: $deletedAt
      deliveryDate: $deliveryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        senderOU_destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        status
        packages {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsByDeletedAtAndSenderOU = /* GraphQL */ `
  query ShipmentsByDeletedAtAndSenderOU(
    $deletedAt: Int!
    $senderOU: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsByDeletedAtAndSenderOU(
      deletedAt: $deletedAt
      senderOU: $senderOU
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        senderOU_destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        status
        packages {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsByDeletedAtAndDestinationOU = /* GraphQL */ `
  query ShipmentsByDeletedAtAndDestinationOU(
    $deletedAt: Int!
    $destinationOU: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsByDeletedAtAndDestinationOU(
      deletedAt: $deletedAt
      destinationOU: $destinationOU
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentType
        senderUserId
        receiverUserId
        senderOU
        destinationOU
        senderOU_destinationOU
        trackingNumber
        shipmentDate
        deliveryDate
        shipmentComments
        deliveryComments
        status
        packages {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dispensesBySenderOU_scan = /* GraphQL */ `
  query DispensesBySenderOU_scan(
    $senderOU_scan: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDispenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dispensesBySenderOU_scan(
      senderOU_scan: $senderOU_scan
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        senderUserId
        senderOU
        senderOU_scan
        patientIdNumber
        patientIdNumber_scan
        patientOrganizationalUnit_scan
        currentLocation
        currentLocation_scan
        dispenseDate
        dispenseComments
        returnDate
        returnComments
        package {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        status
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dispensesByPatientIdNumber_scanAndDispenseDate = /* GraphQL */ `
  query DispensesByPatientIdNumber_scanAndDispenseDate(
    $patientIdNumber_scan: String!
    $dispenseDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDispenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dispensesByPatientIdNumber_scanAndDispenseDate(
      patientIdNumber_scan: $patientIdNumber_scan
      dispenseDate: $dispenseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        senderUserId
        senderOU
        senderOU_scan
        patientIdNumber
        patientIdNumber_scan
        patientOrganizationalUnit_scan
        currentLocation
        currentLocation_scan
        dispenseDate
        dispenseComments
        returnDate
        returnComments
        package {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        status
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dispensesByPatientOrganizationalUnit_scanAndDispenseDate = /* GraphQL */ `
  query DispensesByPatientOrganizationalUnit_scanAndDispenseDate(
    $patientOrganizationalUnit_scan: String!
    $dispenseDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDispenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dispensesByPatientOrganizationalUnit_scanAndDispenseDate(
      patientOrganizationalUnit_scan: $patientOrganizationalUnit_scan
      dispenseDate: $dispenseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        senderUserId
        senderOU
        senderOU_scan
        patientIdNumber
        patientIdNumber_scan
        patientOrganizationalUnit_scan
        currentLocation
        currentLocation_scan
        dispenseDate
        dispenseComments
        returnDate
        returnComments
        package {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        status
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dispensesByCurrentLocation_scanAndDispenseDate = /* GraphQL */ `
  query DispensesByCurrentLocation_scanAndDispenseDate(
    $currentLocation_scan: String!
    $dispenseDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDispenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dispensesByCurrentLocation_scanAndDispenseDate(
      currentLocation_scan: $currentLocation_scan
      dispenseDate: $dispenseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        senderUserId
        senderOU
        senderOU_scan
        patientIdNumber
        patientIdNumber_scan
        patientOrganizationalUnit_scan
        currentLocation
        currentLocation_scan
        dispenseDate
        dispenseComments
        returnDate
        returnComments
        package {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        status
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dispensesByDeletedAtAndSenderOU_scan = /* GraphQL */ `
  query DispensesByDeletedAtAndSenderOU_scan(
    $deletedAt: Int!
    $senderOU_scan: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDispenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dispensesByDeletedAtAndSenderOU_scan(
      deletedAt: $deletedAt
      senderOU_scan: $senderOU_scan
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        senderUserId
        senderOU
        senderOU_scan
        patientIdNumber
        patientIdNumber_scan
        patientOrganizationalUnit_scan
        currentLocation
        currentLocation_scan
        dispenseDate
        dispenseComments
        returnDate
        returnComments
        package {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        status
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dispensesByDeletedAtAndPatientOrganizationalUnit_scan = /* GraphQL */ `
  query DispensesByDeletedAtAndPatientOrganizationalUnit_scan(
    $deletedAt: Int!
    $patientOrganizationalUnit_scan: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDispenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dispensesByDeletedAtAndPatientOrganizationalUnit_scan(
      deletedAt: $deletedAt
      patientOrganizationalUnit_scan: $patientOrganizationalUnit_scan
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        senderUserId
        senderOU
        senderOU_scan
        patientIdNumber
        patientIdNumber_scan
        patientOrganizationalUnit_scan
        currentLocation
        currentLocation_scan
        dispenseDate
        dispenseComments
        returnDate
        returnComments
        package {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        status
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cleaningsByCleaningUserIdAndCleaningDate = /* GraphQL */ `
  query CleaningsByCleaningUserIdAndCleaningDate(
    $cleaningUserId: String!
    $cleaningDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCleaningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cleaningsByCleaningUserIdAndCleaningDate(
      cleaningUserId: $cleaningUserId
      cleaningDate: $cleaningDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cleaningUserId
        currentLocation
        currentLocation_scan
        cleaningMaterial
        cleaningMaterialExpirationDate
        cleaningDate
        comments
        package {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cleaningsByCurrentLocation_scanAndCleaningDate = /* GraphQL */ `
  query CleaningsByCurrentLocation_scanAndCleaningDate(
    $currentLocation_scan: String!
    $cleaningDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCleaningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cleaningsByCurrentLocation_scanAndCleaningDate(
      currentLocation_scan: $currentLocation_scan
      cleaningDate: $cleaningDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cleaningUserId
        currentLocation
        currentLocation_scan
        cleaningMaterial
        cleaningMaterialExpirationDate
        cleaningDate
        comments
        package {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cleaningsByDeletedAtAndCurrentLocation_scan = /* GraphQL */ `
  query CleaningsByDeletedAtAndCurrentLocation_scan(
    $deletedAt: Int!
    $currentLocation_scan: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCleaningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cleaningsByDeletedAtAndCurrentLocation_scan(
      deletedAt: $deletedAt
      currentLocation_scan: $currentLocation_scan
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cleaningUserId
        currentLocation
        currentLocation_scan
        cleaningMaterial
        cleaningMaterialExpirationDate
        cleaningDate
        comments
        package {
          id
          containerType_Id
          packageType
          packageSerialNumber
          currentLocation
          currentLocation_scan
          condition
          status
          items {
            id
            index
            serialNumber
            rirNumber
            itemType
            quantity
            currentLocation
            missing
            returned
            refurbished
            damaged
            cleaned
            status
            __typename
          }
          createdAt
          updatedAt
          deletedAt
          log {
            serialNumber
            containerType_Id
            timestamp
            userId
            location
            location_scan
            action
            status
            id
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const packagesByContainerType_IdAndUpdatedAt = /* GraphQL */ `
  query PackagesByContainerType_IdAndUpdatedAt(
    $containerType_Id: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packagesByContainerType_IdAndUpdatedAt(
      containerType_Id: $containerType_Id
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        containerType_Id
        packageType
        packageSerialNumber
        currentLocation
        currentLocation_scan
        condition
        status
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const packagesByPackageTypeAndUpdatedAt = /* GraphQL */ `
  query PackagesByPackageTypeAndUpdatedAt(
    $packageType: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packagesByPackageTypeAndUpdatedAt(
      packageType: $packageType
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        containerType_Id
        packageType
        packageSerialNumber
        currentLocation
        currentLocation_scan
        condition
        status
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const packagesByPackageSerialNumberAndCurrentLocation = /* GraphQL */ `
  query PackagesByPackageSerialNumberAndCurrentLocation(
    $packageSerialNumber: String!
    $currentLocation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packagesByPackageSerialNumberAndCurrentLocation(
      packageSerialNumber: $packageSerialNumber
      currentLocation: $currentLocation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        containerType_Id
        packageType
        packageSerialNumber
        currentLocation
        currentLocation_scan
        condition
        status
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const packagesByCurrentLocation_scanAndUpdatedAt = /* GraphQL */ `
  query PackagesByCurrentLocation_scanAndUpdatedAt(
    $currentLocation_scan: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packagesByCurrentLocation_scanAndUpdatedAt(
      currentLocation_scan: $currentLocation_scan
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        containerType_Id
        packageType
        packageSerialNumber
        currentLocation
        currentLocation_scan
        condition
        status
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const packagesByConditionAndUpdatedAt = /* GraphQL */ `
  query PackagesByConditionAndUpdatedAt(
    $condition: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packagesByConditionAndUpdatedAt(
      condition: $condition
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        containerType_Id
        packageType
        packageSerialNumber
        currentLocation
        currentLocation_scan
        condition
        status
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const packagesByStatusAndUpdatedAt = /* GraphQL */ `
  query PackagesByStatusAndUpdatedAt(
    $status: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packagesByStatusAndUpdatedAt(
      status: $status
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        containerType_Id
        packageType
        packageSerialNumber
        currentLocation
        currentLocation_scan
        condition
        status
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const packagesByStatusAndCurrentLocation_scan = /* GraphQL */ `
  query PackagesByStatusAndCurrentLocation_scan(
    $status: String!
    $currentLocation_scan: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packagesByStatusAndCurrentLocation_scan(
      status: $status
      currentLocation_scan: $currentLocation_scan
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        containerType_Id
        packageType
        packageSerialNumber
        currentLocation
        currentLocation_scan
        condition
        status
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const packagesByDeletedAtAndCurrentLocation_scan = /* GraphQL */ `
  query PackagesByDeletedAtAndCurrentLocation_scan(
    $deletedAt: Int!
    $currentLocation_scan: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packagesByDeletedAtAndCurrentLocation_scan(
      deletedAt: $deletedAt
      currentLocation_scan: $currentLocation_scan
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        containerType_Id
        packageType
        packageSerialNumber
        currentLocation
        currentLocation_scan
        condition
        status
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsBySerialNumberAndUpdatedAt = /* GraphQL */ `
  query InventoryItemsBySerialNumberAndUpdatedAt(
    $serialNumber: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsBySerialNumberAndUpdatedAt(
      serialNumber: $serialNumber
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsByContainerType_IdAndUpdatedAt = /* GraphQL */ `
  query InventoryItemsByContainerType_IdAndUpdatedAt(
    $containerType_Id: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsByContainerType_IdAndUpdatedAt(
      containerType_Id: $containerType_Id
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsByItemTypeAndUpdatedAt = /* GraphQL */ `
  query InventoryItemsByItemTypeAndUpdatedAt(
    $itemType: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsByItemTypeAndUpdatedAt(
      itemType: $itemType
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsByItemTypeAndCurrentLocation_scan = /* GraphQL */ `
  query InventoryItemsByItemTypeAndCurrentLocation_scan(
    $itemType: String!
    $currentLocation_scan: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsByItemTypeAndCurrentLocation_scan(
      itemType: $itemType
      currentLocation_scan: $currentLocation_scan
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsByCurrentLocation_scanAndUpdatedAt = /* GraphQL */ `
  query InventoryItemsByCurrentLocation_scanAndUpdatedAt(
    $currentLocation_scan: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsByCurrentLocation_scanAndUpdatedAt(
      currentLocation_scan: $currentLocation_scan
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsByPatientIdNumber_scanAndUpdatedAt = /* GraphQL */ `
  query InventoryItemsByPatientIdNumber_scanAndUpdatedAt(
    $patientIdNumber_scan: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsByPatientIdNumber_scanAndUpdatedAt(
      patientIdNumber_scan: $patientIdNumber_scan
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsByStatusAndUpdatedAt = /* GraphQL */ `
  query InventoryItemsByStatusAndUpdatedAt(
    $status: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsByStatusAndUpdatedAt(
      status: $status
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsByStatusAndCurrentLocation_scan = /* GraphQL */ `
  query InventoryItemsByStatusAndCurrentLocation_scan(
    $status: String!
    $currentLocation_scan: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsByStatusAndCurrentLocation_scan(
      status: $status
      currentLocation_scan: $currentLocation_scan
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsByStatus_itemTypeAndUpdatedAt = /* GraphQL */ `
  query InventoryItemsByStatus_itemTypeAndUpdatedAt(
    $status_itemType: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsByStatus_itemTypeAndUpdatedAt(
      status_itemType: $status_itemType
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsByStatus_itemTypeAndCurrentLocation_scan = /* GraphQL */ `
  query InventoryItemsByStatus_itemTypeAndCurrentLocation_scan(
    $status_itemType: String!
    $currentLocation_scan: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsByStatus_itemTypeAndCurrentLocation_scan(
      status_itemType: $status_itemType
      currentLocation_scan: $currentLocation_scan
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryItemsByDeletedAtAndCurrentLocation_scan = /* GraphQL */ `
  query InventoryItemsByDeletedAtAndCurrentLocation_scan(
    $deletedAt: Float!
    $currentLocation_scan: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryItemsByDeletedAtAndCurrentLocation_scan(
      deletedAt: $deletedAt
      currentLocation_scan: $currentLocation_scan
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        rirNumber
        containerType_Id
        itemType
        currentLocation
        currentLocation_scan
        patientIdNumber
        patientIdNumber_scan
        status
        status_itemType
        latestAction
        dispensedAt
        updatedAt
        deletedAt
        log {
          serialNumber
          containerType_Id
          timestamp
          userId
          location
          location_scan
          action
          status
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryLogsBySerialNumberAndTimestamp = /* GraphQL */ `
  query InventoryLogsBySerialNumberAndTimestamp(
    $serialNumber: String!
    $timestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryLogsBySerialNumberAndTimestamp(
      serialNumber: $serialNumber
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        serialNumber
        containerType_Id
        timestamp
        userId
        location
        location_scan
        action
        status
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryLogsByContainerType_IdAndTimestamp = /* GraphQL */ `
  query InventoryLogsByContainerType_IdAndTimestamp(
    $containerType_Id: String!
    $timestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryLogsByContainerType_IdAndTimestamp(
      containerType_Id: $containerType_Id
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        serialNumber
        containerType_Id
        timestamp
        userId
        location
        location_scan
        action
        status
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryLogsByUserId = /* GraphQL */ `
  query InventoryLogsByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryLogsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        serialNumber
        containerType_Id
        timestamp
        userId
        location
        location_scan
        action
        status
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryLogsByLocation_scan = /* GraphQL */ `
  query InventoryLogsByLocation_scan(
    $location_scan: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryLogsByLocation_scan(
      location_scan: $location_scan
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        serialNumber
        containerType_Id
        timestamp
        userId
        location
        location_scan
        action
        status
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryLogsByAction = /* GraphQL */ `
  query InventoryLogsByAction(
    $action: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryLogsByAction(
      action: $action
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        serialNumber
        containerType_Id
        timestamp
        userId
        location
        location_scan
        action
        status
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
