import * as React from 'react';
import BQCRUDBuilder from "../Generic/BQCRUDComponent"
import { ShipmentList } from './shipment.component';
import { BQListToolbar, BQToolbar } from '../Generic/BQUI';
import { PackageList } from './package.component';
import { Button, RefreshIconButton, useRedirect } from 'react-admin';
import { InventoryList } from './inventory.component';
import { BQSimpleForm } from '../Generic/bq-form-components';
import { DispenseList } from './dispense.component';
import { CleaningList } from './cleaning.component';
import { BQTab, BQTabsLayout } from '../Generic/bq-tabs';
import { useGetAppSettings } from '../../utils/globals';
import { SubjectList } from './subjects.component';
import { AuditTrailList } from '../auditTrail.component'
import { bqAuthData } from '../../utils/bq-auth';

const InventoryDashboard = () => {
    const { isSuperAdmin } = bqAuthData
    const { shipmentTypes } = useGetAppSettings()
    const redirect = useRedirect()
    const [display, setDisplay] = React.useState(true)
    const [currentTabIndex, setCurrentTabIndex] = React.useState(getLatestTabIndex())

    const refreshLists = () => {
        setDisplay(false)
        setTimeout(() => setDisplay(true), 10)
    }
    return !!shipmentTypes && <>

        <BQSimpleForm>
            <BQToolbar label="Inventory Dashboard" hideButtons hideBackButton />
        </BQSimpleForm>

        <div style={{ position: 'fixed', right: 42, top: 44, zIndex: 10000 }}>
            <BQListToolbar hideExport hideAddItem>
                <Button onClick={() => redirect(`/dispenses/create`)}>Dispense to Subject</Button>
                <Button onClick={() => redirect(`/shipments/create?shipmentType=${shipmentTypes.BACK_TO_WAREHOUSE}`)}>Send Back to BrainQ</Button>
                <Button onClick={() => redirect(`/cleanings/create`)}>Cleaning Form</Button>
                <Button onClick={() => redirect(`/shipments/create?shipmentType=${shipmentTypes.TRANSACTION}`)}>Create Shipment</Button>
                <span title="Refresh">
                    <RefreshIconButton onClick={refreshLists} />
                </span>
            </BQListToolbar>
        </div>


        <BQTabsLayout
            currentTabIndex={currentTabIndex}
            onTabChange={(tabIndex) => {
                setCurrentTabIndex(tabIndex)
                saveCurrentTabIndex(tabIndex)
            }}
        >
            <BQTab label="My Inventory">
                <RefreshableSection display={display}>
                    <PackageList onRefresh={refreshLists} />
                    <InventoryList />
                </RefreshableSection>
            </BQTab>
            <BQTab label="Shipments">
                <RefreshableSection display={display}>
                    <ShipmentList />
                </RefreshableSection>
            </BQTab>
            <BQTab label="Dispenses">
                <RefreshableSection display={display}>
                    <SubjectList />
                    <DispenseList />
                    <CleaningList />
                </RefreshableSection>
            </BQTab>
            {!!isSuperAdmin && <BQTab label="Audit Trail" cached={false}>
                <AuditTrailList />
            </BQTab>}
        </BQTabsLayout>
    </>
}

const getLatestTabIndex = () => parseInt(sessionStorage.getItem('currentDashboardTabIndex')) || 0

const saveCurrentTabIndex = (tabIndex) => sessionStorage.setItem('currentDashboardTabIndex', tabIndex.toString())

const RefreshableSection = ({ display, children }) => {
    return !!display && <>{children}</>
}

export default BQCRUDBuilder({
    List: InventoryDashboard,
})