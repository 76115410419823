/* eslint-disable react/display-name */
import { Amplify } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { Admin, DataProviderContext, Layout, Resource, SimpleForm } from 'react-admin';
import awsExports from './aws-exports';

import { bq_theme } from './themes';
import * as Auth from '@aws-amplify/auth';
import { bqAuthData, clearBQAuthData, setBQAuthData } from './utils/bq-auth';

import InventoryDashboard from './components/inventoryManagement/inventoryDashboard';
import ShipmentsComponent from './components/inventoryManagement/shipment.component';
import PackageComponent from './components/inventoryManagement/package.component';
import DispenseComponent from './components/inventoryManagement/dispense.component';
import CleaningComponent from './components/inventoryManagement/cleaning.component';
import InventoryComponent from './components/inventoryManagement/inventory.component';
import SubjectComponent from './components/inventoryManagement/subjects.component';

import langSelected from './redux/reducers/languageReducer'
import tempPasswordObject from './redux/reducers/tempPasswordReducer'
import { LanguageSelection } from './components/Generic/bq-form-components';
import BQDataProvider from './BQDataProvider';
import TempPasswordDialog from './components/Generic/TempPasswordDialog';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';

import { useBQRouter } from './components/Generic/BQRouter';
import { getFromCache } from './utils/globals';
import { getCookieValue } from './utils/textUtils';

Amplify.configure(awsExports);

const App = (props) => {
    const bqClasses = getFromCache('bqClasses');

    const [userName, setUserName] = useState();

    useEffect(() => {
        Auth.fetchAuthSession().then(autheticatedUser => {
            if (!autheticatedUser?.tokens) {
                autheticatedUser.tokens = {
                    idToken: Auth.decodeJWT(getCookieValue('idToken')),
                }
            }
            setBQAuthData(autheticatedUser)
            setUserName(bqAuthData.name);
        })
    }, [])

    const { checkAuthState } = props;

    const {
        userAllowed,
        isSuperAdmin,
        isInventory,
        isAdmin,
        isOperator,
        isAuditor,
        isViewer,
    } = bqAuthData

    if (!bqAuthData.id) {
        return <></>
    }

    return !bqAuthData.role ?
        <>Role is not set for current user</>
        :
        <Provider
            store={configureStore({ reducer: combineReducers({ langSelected, tempPasswordObject }) })}>
            <Admin
                layout={BQLayout}
                dataProvider={BQDataProvider}
                authData={bqAuthData}
                theme={bq_theme}
                logoutButton={null}
                refreshButton={null}
                ready={null}
                options={{
                    enableAdminQueries: false
                }}>

                <Resource
                    name="inventories"
                    list={(isSuperAdmin || isInventory) && InventoryDashboard}
                    options={{ label: 'Inventory' }}
                />

                <Resource name="shipments"
                    create={ShipmentsComponent}
                    edit={ShipmentsComponent}
                />
                <Resource name="packages"
                    create={PackageComponent}
                    edit={PackageComponent}
                />
                <Resource name="dispenses"
                    create={DispenseComponent}
                    edit={DispenseComponent}
                />
                <Resource name="cleanings"
                    create={CleaningComponent}
                    edit={CleaningComponent}
                />

                <Resource name="inventoryItems"
                    edit={InventoryComponent}
                />

                <Resource name="subjects"
                    edit={SubjectComponent}
                />

                <Resource name="auditTrails"
                    edit={<></>}
                />

            </Admin>
            {!userAllowed && <div id="not_authorized_label" style={{ position: 'absolute', top: '50%', left: '50%', height: '64px', zIndex: '99999' }}>The Admin panel is not available for given user role</div> || <></>}
        </Provider>
}

const BQLayout = (props) => {
    useBQRouter(props)
    return <Layout>
        {props.children}
    </Layout>
}


export default App