import { useState } from "react";
import { bqBlue } from "../../themes";
const { Tabs, Tab } = require("@material-ui/core");

export const BQTab = (props) => {
    return props
}

export const BQTabsLayout = ({ children, onTabChange, currentTabIndex }) => {
    const [value, setValue] = useState(currentTabIndex || 0);
    const bqTabs = Array.isArray(children) ? children : [children];

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onTabChange?.(newValue);
    };

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { backgroundColor: bqBlue } }}
            >
                {bqTabs.map(tab => tab.props && <Tab label={tab.props.label} />)}
            </Tabs>
            {bqTabs.map((tab, i) => tab.props && <BQTabPanel value={value} index={i} {...tab.props} />)}
        </>
    );
}

const BQTabPanel = (props) => {
    const [shown, setShown] = useState(false);
    const { children, value, index, label, cached = true, ...other } = props;
    if (!shown && value === index) {
        setShown(true);
    }
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`bq-tabpanel-${label.replace(/\s+/, '_')}`}
            style={{ paddingTop: '16px' }}
            {...other}
        >
            {((cached && shown) || index === value) && children}
        </div>
    );
}