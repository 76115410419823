import { clearObject, resetGlobals } from "./globals"

export const bqAuthData = {}

export const setBQAuthData = (authSession) => {
    const autheticatedUser = authSession?.tokens?.idToken?.payload

    const claims = JSON.parse(autheticatedUser['cognito:groups']?.[0] || autheticatedUser['cognito:groups'])

    bqAuthData.id = autheticatedUser.sub
    bqAuthData.isMarketTargetAudience = claims?.TARGET_AUDIENCE?.indexOf('MARKET') > -1
    bqAuthData.isAlzheimers = claims?.TARGET_AUDIENCE?.indexOf('ALZ_') > -1
    bqAuthData.email = autheticatedUser.email
    bqAuthData.name = autheticatedUser.name || autheticatedUser.email
    bqAuthData.role = claims.role
    bqAuthData.ou = claims.organizationalUnit
    bqAuthData.ouId = claims.organizationalUnitId

    bqAuthData.isAdmin = !!bqAuthData.role.some(role => role.match(/administrator/i));
    bqAuthData.isSuperAdmin = !!bqAuthData.role.some(role => role.match(/super administrator/i));
    if (!bqAuthData.isMarketTargetAudience) {
        bqAuthData.isRandomizer = !!bqAuthData.role.some(role => role.match(/random/i));
    }
    bqAuthData.isAuditor = !!bqAuthData.role.some(role => role.match(/auditor/i));
    bqAuthData.isOperator = !!(bqAuthData.role.some(role => role.match(/operator/i)));
    bqAuthData.isViewer = !!(bqAuthData.role.some(role => role.match(/viewer/i)));
    bqAuthData.subjectOrPatient = bqAuthData.isMarketTargetAudience ? 'Patient' : 'Subject'
    bqAuthData.isInventoryAdmin = !!bqAuthData.role.some(item => item.match(/warehouse manager/i))
    bqAuthData.isInventory = bqAuthData.isInventoryAdmin || !!bqAuthData.role.some(item => item.match(/inventory/i))
    bqAuthData.userAllowed = bqAuthData.isAdmin || bqAuthData.isAuditor || bqAuthData.isViewer || bqAuthData.isOperator || (!bqAuthData.isMarketTargetAudience && bqAuthData.isRandomizer) || bqAuthData.isInventory || bqAuthData.isInventoryAdmin
}

export const clearBQAuthData = () => {
    document.cookie = `accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `idToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    clearObject(bqAuthData)
    resetGlobals()
    clearFormsData()
}

export const clearFormsData = (expandedOnly) => {
    const keysToRemove = {};
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.indexOf('RaStore') != -1) {
            if (expandedOnly) {
                if (key.indexOf('expanded') != -1) {
                    keysToRemove[key] = true;
                }
            } else {
                keysToRemove[key] = true;
            }
        }
    }
    for (let key in keysToRemove) {
        localStorage.removeItem(key);
    }
}