import { LinearProgress, useRecordContext, useRedirect } from "react-admin"
import BQCRUDBuilder from "../Generic/BQCRUDComponent"
import { useBQGetList, useBQQuery } from "../Generic/hooks"
import { BQToolbar } from "../Generic/BQUI"
import { CustomList, DefaultValueField } from "../Generic/bq-form-components"
import { getDateTime } from "../../utils/textUtils"
import { useParams } from 'react-router-dom';
import React, { useEffect } from "react"
import { useGetAppSettings } from "../../utils/globals"
import { BQFormExporter } from "../../utils/bq_form_export"
import { ItemHistory } from "./inventory.component"

const bqExporter = new BQFormExporter()
export const SubjectList = () => {
    const listData = useBQGetList({ resource: 'dispensedPatients' })
    const redirect = useRedirect()

    return <CustomList
        {...{
            title: 'Subjects',
            maxHeight: 512,
            data: listData?.data,
            sortField: 'dispenseDate',
            sortOrder: 'ASC',
            rowClick: (row, resource, element) => {
                redirect(`/subjects/${element.patientOrganizationalUnit}`)
            }
        }}>
        <DefaultValueField source="dispenseDate" label="First dispense date" value={v => v && getDateTime(new Date(v), true)} />
        <DefaultValueField source="patientOrganizationalUnit" label="idNumber" value={v => v.split('.').pop()} />
    </CustomList>
}

export const SubjectView = () => {
    const { id } = useParams()
    const redirect = useRedirect()
    const { packageTypes, conditions, itemTypes, itemStatuses, actions } = useGetAppSettings()
    const patientIdNumber = id.split('.').pop()
    const data = useBQQuery({ query: 'getDataByPatient', variables: { id } })?.data?.getDataByPatient

    const [inventoryItems, setInventoryItems] = React.useState([])

    useEffect(() => {
        if (data) {
            const tempItems = {}
            data?.inventoryItems?.forEach(element => {
                tempItems[element.id] = element
            });
            setInventoryItems(Object.values(tempItems) || [])
        }
    }, [data])

    return itemStatuses && <>
        <form>
            <BQToolbar label={`Items of ${patientIdNumber}`} hideButtons simpleBack
                forceExporter
                onBack={() => redirect(`/inventories`)}
                exporterLabel="Export form"
                exporter={(formContext) => bqExporter.exportForm({ formType: 'patient', data: data, itemTypes, packageTypes, conditions, itemStatuses, actions })}
            />
        </form>
        {data ? <>
            <CustomList
                {...{
                    title: `Items that were dispensed to ${patientIdNumber}`,
                    data: inventoryItems,
                    sortField: 'itemType',
                    sortOrder: 'ASC'
                }}>
                <DefaultValueField source="dispensedAt" value={v => getDateTime(new Date(v), true)} />
                <DefaultValueField source="itemType" value={v => itemTypes.find(item => item.id === v)?.name} />
                <DefaultValueField source="serialNumber" label="Serial Number (Where applicable)" value={(v, record) => {
                    const itemTypeRecord = itemTypes?.find(item => item.id === record?.itemType)
                    const text = record.serialNumber
                    return itemTypeRecord?.tracedBy == 'SERIAL_NUMBER' ? text : ''
                }} />
                <DefaultValueField label="By user" value={record => record?.log?.find(item => item.location?.toLowerCase() === id.toLowerCase())?.userId || 'NA'} />
            </CustomList>
            {
                inventoryItems.map(inventoryItem =>
                    <ItemHistory inventoryItem={inventoryItem} />
                )
            }
        </>
            : <LinearProgress />
        }
    </>
}

export default BQCRUDBuilder({
    Edit: SubjectView,
    List: SubjectList,
})