/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const uploadPackageCSV = /* GraphQL */ `
  mutation UploadPackageCSV($input: FileUploadInput) {
    uploadPackageCSV(input: $input) {
      id
      containerType_Id
      packageType
      packageSerialNumber
      currentLocation
      currentLocation_scan
      condition
      status
      items {
        id
        index
        serialNumber
        rirNumber
        itemType
        quantity
        currentLocation
        missing
        returned
        refurbished
        damaged
        cleaned
        status
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      log {
        serialNumber
        containerType_Id
        timestamp
        userId
        location
        location_scan
        action
        status
        id
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createInventoryItem = /* GraphQL */ `
  mutation CreateInventoryItem($input: InventoryItemInput) {
    createInventoryItem(input: $input) {
      id
      serialNumber
      rirNumber
      containerType_Id
      itemType
      currentLocation
      currentLocation_scan
      patientIdNumber
      patientIdNumber_scan
      status
      status_itemType
      latestAction
      dispensedAt
      updatedAt
      deletedAt
      log {
        serialNumber
        containerType_Id
        timestamp
        userId
        location
        location_scan
        action
        status
        id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      __typename
    }
  }
`;
export const updateInventoryItem = /* GraphQL */ `
  mutation UpdateInventoryItem($input: InventoryItemInput) {
    updateInventoryItem(input: $input) {
      id
      serialNumber
      rirNumber
      containerType_Id
      itemType
      currentLocation
      currentLocation_scan
      patientIdNumber
      patientIdNumber_scan
      status
      status_itemType
      latestAction
      dispensedAt
      updatedAt
      deletedAt
      log {
        serialNumber
        containerType_Id
        timestamp
        userId
        location
        location_scan
        action
        status
        id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      __typename
    }
  }
`;
export const createShipment = /* GraphQL */ `
  mutation CreateShipment($input: ShipmentInput) {
    createShipment(input: $input) {
      id
      shipmentType
      senderUserId
      receiverUserId
      senderOU
      destinationOU
      trackingNumber
      shipmentDate
      deliveryDate
      shipmentComments
      deliveryComments
      packages {
        id
        packageType
        packageSerialNumber
        condition
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        __typename
      }
      status
      createdAt
      deletedAt
      __typename
    }
  }
`;
export const updateShipment = /* GraphQL */ `
  mutation UpdateShipment($input: ShipmentInput) {
    updateShipment(input: $input) {
      id
      shipmentType
      senderUserId
      receiverUserId
      senderOU
      destinationOU
      trackingNumber
      shipmentDate
      deliveryDate
      shipmentComments
      deliveryComments
      packages {
        id
        packageType
        packageSerialNumber
        condition
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        __typename
      }
      status
      createdAt
      deletedAt
      __typename
    }
  }
`;
export const createDispense = /* GraphQL */ `
  mutation CreateDispense($input: DispenseInput) {
    createDispense(input: $input) {
      id
      senderUserId
      returnUserId
      senderOU
      patientIdNumber
      currentLocation
      dispenseDate
      dispenseComments
      returnDate
      returnComments
      package {
        id
        packageType
        packageSerialNumber
        condition
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        __typename
      }
      status
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDispense = /* GraphQL */ `
  mutation UpdateDispense($input: DispenseInput) {
    updateDispense(input: $input) {
      id
      senderUserId
      returnUserId
      senderOU
      patientIdNumber
      currentLocation
      dispenseDate
      dispenseComments
      returnDate
      returnComments
      package {
        id
        packageType
        packageSerialNumber
        condition
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        __typename
      }
      status
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCleaning = /* GraphQL */ `
  mutation CreateCleaning($input: CleaningInput) {
    createCleaning(input: $input) {
      id
      cleaningUserId
      currentLocation
      cleaningMaterial
      cleaningMaterialExpirationDate
      cleaningDate
      comments
      package {
        id
        packageType
        packageSerialNumber
        condition
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCleaning = /* GraphQL */ `
  mutation UpdateCleaning($input: CleaningInput) {
    updateCleaning(input: $input) {
      id
      cleaningUserId
      currentLocation
      cleaningMaterial
      cleaningMaterialExpirationDate
      cleaningDate
      comments
      package {
        id
        packageType
        packageSerialNumber
        condition
        items {
          id
          index
          serialNumber
          rirNumber
          itemType
          quantity
          currentLocation
          missing
          returned
          refurbished
          damaged
          cleaned
          status
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPackage = /* GraphQL */ `
  mutation CreatePackage($input: PackageInput) {
    createPackage(input: $input) {
      id
      containerType_Id
      packageType
      packageSerialNumber
      currentLocation
      currentLocation_scan
      condition
      status
      items {
        id
        index
        serialNumber
        rirNumber
        itemType
        quantity
        currentLocation
        missing
        returned
        refurbished
        damaged
        cleaned
        status
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      log {
        serialNumber
        containerType_Id
        timestamp
        userId
        location
        location_scan
        action
        status
        id
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updatePackage = /* GraphQL */ `
  mutation UpdatePackage($input: PackageInput) {
    updatePackage(input: $input) {
      id
      containerType_Id
      packageType
      packageSerialNumber
      currentLocation
      currentLocation_scan
      condition
      status
      items {
        id
        index
        serialNumber
        rirNumber
        itemType
        quantity
        currentLocation
        missing
        returned
        refurbished
        damaged
        cleaned
        status
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      log {
        serialNumber
        containerType_Id
        timestamp
        userId
        location
        location_scan
        action
        status
        id
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
