import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect, useState, useContext } from 'react';
import { DataProviderContext, useLoading, useResourceContext } from 'react-admin';
import { getConfig, useGetAppSettings } from '../../utils/globals';

import { useLocation } from 'react-router-dom';
import { bqAuthData, clearFormsData } from '../../utils/bq-auth';

const BQCRUDComponent = (props) => {
    const language = useSelector(state => state.langSelected.language);
    const tempPassword = useSelector(state => state.tempPasswordObject.tempPassword);

    return <WrappedComponent
        {...props}
        Create={props.Create}
        Edit={props.Edit}
        List={props.List}
        language={language}
        tempPassword={tempPassword}
    />;
};

const BQCRUDBuilder = (props) => <BQCRUDComponent {...props} />

const WrappedComponent = (props) => {
    const resource = useResourceContext()
    const isLoading = useLoading()
    const appSettings = useGetAppSettings()

    const location = useLocation()

    const { Create, Edit, List, resetOnList } = props

    const basePath = `/${location.pathname.split('/')[1]}`
    const pathname = location.pathname
    const isCreate = pathname === (`${basePath}/create`)
    const isEdit = !isCreate && pathname.indexOf(`${basePath}/`) === 0

    const { isViewer } = bqAuthData

    let viewName = ''

    let ComponentView = null
    if (isCreate && !isViewer) {
        ComponentView = Create
        viewName = 'Create'
    } else if (isEdit) {
        ComponentView = Edit
        viewName = 'Edit'
    } else {
        if (resetOnList) {
            clearFormsData(true)
        }
        ComponentView = List
        viewName = 'List'
    }

    let loadingStateTimeout = null
    useEffect(() => {
        clearTimeout(loadingStateTimeout)
        const reportingObject = {
            resource,
            view: viewName,
            isLoading
        }
        if (!isLoading && appSettings) {
            loadingStateTimeout = setTimeout(() => {
                document?.onBQReady?.(reportingObject)
            }, 200)
        } else {
            document?.onBQReady?.(reportingObject)
        }
    }, [isLoading, appSettings])
    return <ComponentView {...props} mutationMode="pessimistic" appSettings={appSettings} redirect={'/'} />
}

export default BQCRUDBuilder
