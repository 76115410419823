import * as React from 'react';
import { Button, Create, Edit, useRecordContext, useRedirect } from "react-admin"
import BQCRUDBuilder from "../Generic/BQCRUDComponent"
import { BQInput } from "../Generic/bq-input"
import { ShipmentFormContainer, SinglePackageSection, SubjectSelector } from "./inventoryUI"
import { bqAuthData } from '../../utils/bq-auth';
import { BQSection, BQSideBySide } from '../Generic/BQUI';
import { getDateTime } from '../../utils/textUtils';
import { validate, validateDate, validateReturned } from '../../utils/validations';
import { CustomList, DefaultValueField } from '../Generic/bq-form-components';
import { charBallot, charCheck, editorGlobalProps } from '../../utils/constants';
import { prepareFormData } from '../../utils/transforms';
import { useBQGetList } from '../Generic/hooks';
import { useGetAppSettings } from '../../utils/globals';
import { highlightObject_cleaning, prepareItemInPackage } from '../../utils/inventoryTools';
import { useFormContext } from 'react-hook-form';

export const DispenseList = () => {
    const listData = useBQGetList({ resource: 'dispenses' })
    const redirect = useRedirect()

    return <CustomList
        {...{
            title: 'Dispenses',
            maxHeight: 512,
            data: listData?.data,
            sortField: 'updatedAt',
            sortOrder: 'ASC',
            rowClick: (e) => redirect(`${window.location.href.substring(0, window.location.href.indexOf('#') + 1)}/dispenses/${e}`),
            component: <Button onClick={() => redirect(`/dispenses/create`)}>Dispense to Subject</Button>
        }}>
        <DefaultValueField source="dispenseDate" label="Dispense date" value={v => v && getDateTime(new Date(v), true)} />
        <DefaultValueField source="package" label="Package #" value={v => v?.packageSerialNumber} />
        <DefaultValueField source="patientIdNumber" label="Subject" />
        <DefaultValueField source="returnDate" label="At Subject" value={v => v ? charBallot : charCheck} />
    </CustomList>
}

const DispenseToPatientEditor = () => {
    const { itemTypes, itemStatuses } = useGetAppSettings()
    const itemTypeKeys = {}
    itemTypes?.forEach(item => itemTypeKeys[item.id] = item.id)
    const record = useRecordContext() || {}
    const { isSuperAdmin, isInventoryAdmin } = bqAuthData
    const isSuperUser = isSuperAdmin || isInventoryAdmin
    const dispenseReadOnly = record.id && !isSuperUser
    const returnReadOnly = record.returnDate && !isSuperUser

    const alreadyReturned = !!record?.returnDate
    const [isReturningItems, setIsReturningItems] = React.useState(alreadyReturned)


    return !!itemStatuses && <ShipmentFormContainer {...{
        readOnly: returnReadOnly,
        formType: 'dispense',
        prepareForm: ({ formData, setValue }) => {
            if (!Array.isArray(formData.missing)) {
                setValue('missing', [])
            }
        },
        label: isReturningItems ? `${!record.returnDate ? 'Return from' : 'Dispense info for'} ${record.patientIdNumber?.split('.')?.pop() || 'Subject'}` : `Dispense to ${record.patientIdNumber?.split('.')?.pop() || 'Subject'}`
    }}>
        <BQSideBySide>
            <BQSection
                title="Dispense Info"
                style={{ minWidth: '100%' }}
                component={!isReturningItems && <Button variant='contained' onClick={() => setIsReturningItems(true)}>Return Dispense</Button>}
            >
                <SubjectSelector
                    source="patientIdNumber"
                    useNameAsValue
                    label="Dispensed to subject"
                    readOnly={dispenseReadOnly}
                    validate={validate}
                    onChange={({ selection, formContext }) => {
                        formContext.setValue('currentLocation', selection.content.organizationalUnit)
                        formContext.setValue('patientOrganizationalUnit', selection.content.organizationalUnit)
                    }}
                />
                <BQInput source="dispenseDate" label="Dispense date" type="date" readOnly={dispenseReadOnly} validate={validateDate} />
                <BQInput source="dispenseComments" multiline readOnly={dispenseReadOnly} />
            </BQSection>
            {isReturningItems ? <BQSection title="Return status" style={{ minWidth: '100%' }}>
                <BQInput source="returnDate" type="date" validate={value => isSuperUser || !value ? undefined : validateDate(value)} readOnly={returnReadOnly} label="Return date" />
                <BQInput source="returnComments" multiline readOnly={returnReadOnly} />
            </BQSection> : undefined}
        </BQSideBySide>
        <DispensePackageSection {...{
            record,
            itemStatuses,
            isReturningItems,
            alreadyReturned,
            isSuperUser,
            validateReturned
        }} />

    </ShipmentFormContainer >
}

const DispensePackageSection = ({ record, isReturningItems, alreadyReturned, isSuperUser, validateReturned }) => {
    const { itemTypes, itemStatuses } = useGetAppSettings()
    const [highlightItems, setHighlightItems] = React.useState()
    const itemTypeKeys = {}
    itemTypes?.forEach(item => itemTypeKeys[item.id] = item.id)

    const formContext = useFormContext()

    React.useEffect(() => {
        const packageData = formContext?.getValues().package
        if (isReturningItems) {
            setHighlightItems(highlightObject_cleaning)
        } else if (packageData?.items?.length && !packageData?.items?.some(i => i.itemType === itemTypeKeys.PT_KIT)) {
            setHighlightItems({
                text: `Note: Make sure to add PT Kit to the package by clicking on the ADD ITEM FROM INVENTORY button at the bottom of the page`,
                action: () => { }
            })
        } else {
            setHighlightItems(undefined)
        }

    }, [formContext])

    return !!itemStatuses && <SinglePackageSection {... {
        packageStatusFilter: itemStatuses.READY_FOR_USE,
        pack: record.package,
        source: 'package',
        canEditItems: !isReturningItems && !alreadyReturned,
        canEditList: !isReturningItems && !alreadyReturned,
        canEditItemStatus: isReturningItems && !alreadyReturned,
        itemStatusVisible: alreadyReturned,
        mustEditItemStatus: isSuperUser ? undefined : validateReturned,
        highlightItems
    }} />
}

const disposeTransform = async (data) => {
    Object.keys(data).filter(key => key.toLowerCase().indexOf('date') !== -1).forEach(key => {
        if (data[key]) {
            data[key] = new Date(data[key]).getTime()
        }
    })
    const { package: p } = data
    data.package = {
        ...p,
        items: p.items.map((item, index) => prepareItemInPackage(item, index)),
    }

    data = {
        id: data.id,
        senderOU: data.senderOU,
        currentLocation: data.currentLocation,
        patientIdNumber: data.patientIdNumber,
        patientOrganizationalUnit: data.patientOrganizationalUnit,
        dispenseDate: data.dispenseDate,
        returnDate: data.returnDate,
        returnComments: data.returnComments,
        comments: data.comments,
        package: data.package,
        deletedAt: data.deletedAt
    }
    return prepareFormData(data)
}

const DispenseMutation = (isCreate, props) => {
    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => disposeTransform(data) }
    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <DispenseToPatientEditor {...props} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <DispenseToPatientEditor {...props} />
                </Edit>)
            }
        </div>
    )
}

export const DispenseCreate = (props) => DispenseMutation(true, props)

export const DispenseEdit = (props) => DispenseMutation(false, props)

export default BQCRUDBuilder({
    Create: DispenseCreate,
    Edit: DispenseEdit,
    List: DispenseList,
})