// In theme.js
import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import createPalette from '@material-ui/core/styles/createPalette';
import { createTheme, makeStyles } from '@material-ui/core';

export const bqBorderBottom = 'solid 2px #51d4d9'

export const bqBlue = 'rgba(81, 212, 217, 1)'

const palette = createPalette(
    merge({}, defaultTheme.palette, {
        primary: {
            main: bqBlue
        },
        secondary: {
            main: bqBlue
        },
    })
);

const typography = {
    fontFamilySecondary: '\'Poppins\', sans-serif',
    fontFamily: 'Geomanist Regular',
    fontSize: 16, // Should be a number in pixels
    fontStyle: 'normal',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    color: palette.text.primary,
};

const typographyBase = {
    fontFamily: typography.fontFamily,
    fontSize: typography.fontSize,
    fontStyle: typography.fontStyle,
    color: typography.color,
};

const typographyHeader = {
    ...typographyBase,
    fontWeight: typography.fontWeightBold,
    fontFamily: typography.fontFamilySecondary, // Use a dedicated font for the header
};

const typographyBody = {
    ...typographyBase,
    fontWeight: typography.fontWeightRegular,
    fontFamily: typography.fontFamily,
};


const normalTextSize = '14px';
const textColor = '#2b3b4f'

const bqInputBorder = {
    border: 'solid 1px rgba(193, 201, 206, 0.3)',
    borderRadius: '3px',
    overflow: 'hidden'
}

export const bq_theme = createTheme({
    ...defaultTheme,
    palette: {
        primary: {
            main: '#666', // Not far from red
        },
        secondary: {
            main: '#FFFFFF', // Not far from green
        },
    },
    '&:#filter_input': {
        paddingLeft: '64px'
    },
    fontFamily: typography.fontFamily,
    PhoneInputCountryIconUnicode: {
        fontSize: '24px'
    },
    sidebar: {
        position: 'fixed',
        color: 'white',
        zIndex: 1000,
        top: 0,
        bottom: '0px',
        paddingTop: '64px',
        background: 'url(\'./brainq-logo.svg\') black no-repeat 44px 32px',
        backgroundColor: 'black',
        backgroundSize: '120px 26px',
        display: 'none'
    },
    components: {
        RaListToolbar: {
            styleOverrides: {
                root: {
                    '& .RaListToolbar-floating': {
                        position: 'absolute',
                        right: 0,
                        paddingTop: '14px',
                        marginTop: '20px'
                    }
                }
            }
        },
        RaAppBar: {
            styleOverrides: {
                root: {
                    '& .RaAppBar-title': {
                        marginTop: '48px',
                        marginLeft: '224px',
                        color: '#000000'
                    }
                }
            }
        },
        RaNotification: {
            styleOverrides: {
                root: {
                    '& .RaNotification-success': {
                        color: 'white',
                        backgroundColor: bqBlue
                    }
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    display: 'none'
                },
            }
        },
        MuiDialog: {
            paper: {
                overflowY: 'visible !important',
            }
        },
        MuiDialogContent: {
            root: {
                overflowY: 'visible!important'
            }
        },
        RaSidebar: {
            styleOverrides: {
                root: {
                    position: 'fixed',
                    color: 'white',
                    zIndex: 1000,
                    top: 0,
                    bottom: 0,
                    height: '100%',
                    paddingTop: '64px',
                    background: 'url(\'./brainq-logo.svg\') black no-repeat 44px 32px',
                    backgroundSize: '120px 26px',
                    width: '208px',
                    '& .RaSidebar-fixed': {
                        overflowX: 'visible'
                    },
                    display: 'none'
                },
            }
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    backgroundColor: 'black',
                    marginLeft: '0px',
                    paddingLeft: '28px',
                    color: 'white !important',
                    width: '208px',
                    '&:hover': {
                        color: 'white',
                        background: 'url(\'./icons/icons-menu-hover.svg\') no-repeat rgba(255, 255, 255, 0.2) 0px 0px',
                    },
                    '&.RaMenuItemLink-active': {
                        color: 'white',
                        backgroundColor: 'rgba(81, 212, 217, 0.4) !important',
                        background: 'url(\'./icons/icons-menu-selected.svg\') black no-repeat 0px 0px',
                        '&:hover': {
                            color: 'white',
                            backgroundColor: 'rgba(81, 212, 217, 0.3) !important',
                        },
                    },
                    '& .RaMenuItemLink-icon': {
                        display: 'none'
                    }
                }
            }
        },
        RaAutocompleteSuggestionItem: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    borderBottom: 'solid thin rgba(0, 0, 0, 0.1)'
                }
            }
        },
        RaLayout: {
            styleOverrides: {
                root: {
                    zIndex: 0,
                    '& .RaLayout-content': {
                        marginLeft: '0px',
                        marginRight: '24px',
                        marginTop: '48px',
                        paddingTop: '48px',
                        paddingLeft: '32px',
                        backgroundColor: 'white',
                        zIndex: 1000,
                    },
                    '& .RaLayout-contentWithSidebar': {
                        backgroundColor: 'white',
                    }
                },
            }
        },
        RaFormInput: {
            styleOverrides: {
                root: {
                    '& .RaFormInput-input': {
                        width: 'auto'
                    }
                }
            }
        },
        RaLoadingIndicator: {
            styleOverrides: {
                root: {
                    display: 'none'
                }
            }
        },
        PrivateNotchedOutline: {
            styleOverrides: {
                legendNotched: {
                    display: 'none'
                }
            }
        },
        RaAutocompleteInput: {
            styleOverrides: {
                suggestionsContainer: {
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                }
            }
        },
        RaAutocompleteArrayInput: {
            styleOverrides: {
                root: {
                    '& .RaAutocompleteArrayInput-suggestionsContainer': {
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                    },
                    '& .RaAutocompleteArrayInput-chipContainerOutlined': {
                        margin: 0
                    }
                }
            }
        },
        RaDatagrid: {
            styleOverrides: {
                root: {
                    '& .RaDatagrid-thead': {
                        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',

                    },
                    '& .RaDatagrid-headerCell': {
                        fontSize: normalTextSize,
                        fontWeight: 'bold',
                        color: textColor,
                        lineHight: '1.33em'
                    },
                    '& .RaDatagrid-rowCell': {
                        fontSize: normalTextSize,
                        color: textColor,
                    }
                }
            }
        },
        RaButton: {
            styleOverrides: {
                root: {
                    '& .RaButton-button': {
                        width: '112px',
                        height: '40px',
                        padding: '11px 34px',
                        borderRadius: '3px',
                        border: 'solid 1px rgba(193, 201, 206, 0.3)',
                        fontSize: '14px',
                        textAlign: 'center',
                        color: textColor,
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            boxShadow: 'none',
                        },
                    },
                    '& .RaButton-smallIcon': {
                        display: 'none'
                    }
                }
            }
        },
        RaSaveButton: {
            styleOverrides: {
                root: {
                    '& .RaSaveButton-button': {
                        width: '113px',
                        height: '40px',
                        margin: '0 0 0 16px',
                        padding: '11px 41px 11px 42px',
                        borderRadius: '3px',
                        backgroundColor: '#51d4d9',
                        boxShadow: 'none',
                        '&:hover': {
                            width: '113px',
                            height: '40px',
                            margin: '0 0 0 16px',
                            padding: '11px 41px 11px 42px',
                            borderRadius: '3px',
                            backgroundColor: '#51d4d9',
                            boxShadow: 'none',
                        },
                    },
                    '& .RaSaveButton-icon': {
                        display: 'none'
                    }
                }
            }
        },
        RaSimpleFormIterator: {
            styleOverrides: {
                root: {
                    '& .RaSimpleFormIterator-line': {
                        borderBottom: 'none !important'
                    },
                    '& .RaSimpleFormIterator-bindexutton': {
                        fontSize: normalTextSize,
                        marginTop: '6px'
                    },
                    '& .RaSimpleFormIterator-form': {
                        flex: 'none',
                        marginTop: '-8px'
                    },
                    '& .RaSimpleFormIterator-action': {
                        paddingTop: '14px'
                    },
                    '& .RaSimpleFormIterator-valueLabel': {
                        padding: 0,
                        margin: 0,
                        marginTop: 0
                    },
                    '& .RaSimpleFormIterator-textInputContainer': {
                        width: '100%'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                root: {
                    display: 'none !important',
                },
                popper: {
                    display: 'none !important',
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    overflow: 'visible !important',
                },
                elevation1: {
                    boxShadow: 'none'
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    overflow: 'visible !important',
                }
            }
        },
        MuiCardContetnt: {
            styleOverrides: {
                root: {
                    paddingTop: '64px'
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    marginTop: '0px !important'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                positionFixed: {
                    zIndex: 1,
                    boxShadow: 'none',
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: bqInputBorder,
                transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    border: 'none',
                },
                adornedEnd: {
                    padding: 0,
                },
                inputMultiline: {
                    padding: '0 !important'
                },
                notchedOutline: {
                    border: 'solid thin',
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                    marginTop: '6px'
                },
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: '18px'
                },
                label: {
                    paddingLeft: '6px',
                    paddingRight: '10px',
                    fontSize: '14px'
                },
                deleteIcon: {
                    width: '16px'
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    display: 'none'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    boxShadow: 'none',
                    padding: '8px 16px',
                    minWidth: '100px',
                    marginLeft: '8px',
                    '& .MuiSvgIcon-root': {
                        display: 'none'
                    }
                },
                textPrimary: {
                    border: 'solid 1px rgba(193, 201, 206, 0.3)',
                },
                containedPrimary: {
                    color: 'white !important',
                    backgroundColor: '#51d4d9',
                    '&:hover': {
                        backgroundColor: '#51d4d9',
                        color: 'black !important',
                        boxShadow: 'none',
                    },
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorInherit: {
                    //color: 'black'
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    lineHeight: '1.3em'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    border: bqInputBorder,
                    borderColor: 'rgba(0,0,0,.2)',
                    '&.Mui-focused': {
                        backgroundColor: 'transparent',
                    },
                    background: 'none',
                    backgroundColor: 'none',
                    '&:hover': {
                        background: 'none',
                        backgroundColor: 'none',
                        border: 'solid 1px black'
                    },
                    '&:focus': {
                        background: 'none',
                        backgroundColor: 'none',
                        border: 'solid 1px black'
                    },
                },
                inputMarginDense: {
                    paddingTop: 12,
                },
                suggestionsContainer: {
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                },
                underline: {
                    '&:hover': {
                        border: 'solid 1px black',
                        '&:before': {
                            borderBottom: 'none',
                        }
                    },
                    '&:before': {
                        borderBottom: 'none',
                    },
                    '&:after': {
                        borderBottom: 'none',
                    },
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                body2: {
                    fontSize: normalTextSize
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                marginDense: {
                    margin: 0,
                    width: '100%'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& legend': {
                        width: 0
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '9px 8px 9px 0px'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    fontSize: '12px',
                    color: 'red !important',
                    paddingTop: '6px'
                }
            }
        }
    },
    typography: {
        ...typographyBase,
        h1: {
            ...typographyHeader,
            textTransform: 'uppercase',
            fontSize: '4rem',

        },
        // ... Put other title styles below
        body1: {
            ...typographyBody,
            fontSize: '1rem',
        }
    }
})

export const useBQStyles = makeStyles(
    theme => ({
        root: {
            padding: 0,
            marginBottom: 0,
            '& > li:last-child': {
                borderBottom: 'none',
            },
        },
        BQTableHeader: {
            fontSize: '16px',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
            lineHeight: '2em',
        },
        BQSectionHeader: {
            fontSize: '14px',
            fontWeight: 'bold',
            paddingBottom: '8px',
            paddingTop: 0,
            borderBottom: bqBorderBottom,
        },
        BQLine: {
            borderBottom: bqBorderBottom
        },
        BQSectionContainer: {
            maxWidth: '640px',
            width: '100%'
        },
        BQSectionContent: {
            width: '100%'
        },
        textInputContainer: {
            clear: 'both',
            width: '100%',
            //padding: '21px 16px 21px 0',
            padding: 0,
            //boxShadow: 'inset 0 -1px 0 0 #e1e2e3',
            verticalAlign: 'top'
        },
        pointer: {
            cursor: 'pointer'
        },
        checkbox: {
            width: '32px'
        },
        line: {
            display: 'flex',
            listStyleType: 'none',
            borderBottom: `solid 1px ${theme.palette.divider}`,
            [theme.breakpoints.down('xs')]: { display: 'block' },
            '&.fade-enter': {
                opacity: 0.01,
                transform: 'translateX(100vw)',
            },
            '&.fade-enter-active': {
                opacity: 1,
                transform: 'translateX(0)',
                transition: 'all 500ms ease-in',
            },
            '&.fade-exit': {
                opacity: 1,
                transform: 'translateX(0)',
            },
            '&.fade-exit-active': {
                opacity: 0.01,
                transform: 'translateX(100vw)',
                transition: 'all 500ms ease-in',
            },
        },
        index: {
            width: '3em',
            paddingTop: '1em',
            [theme.breakpoints.down('sm')]: { display: 'none' },
        },
        form: { flex: 2 },
        inputLabel: {
            width: '33%',
            fontSize: normalTextSize,
            color: '#adb6bb',
            verticalAlign: 'middle',

        },
        textInput: {
            fontSize: normalTextSize,
            color: textColor,
            padding: '9px 0px 9px 0px',
            verticalAlign: 'middle',
            borderRadius: '3px',
        },
        bqInputLabel: {
            fontSize: normalTextSize,
            color: textColor,
            padding: '0px 0px 0px 0px',
            verticalAlign: 'middle',
        },
        bqInputValue: {
            minWidth: '97%',
            fontFamily: typography.fontFamily,
            fontSize: normalTextSize,
            color: textColor,
            padding: '4px ',
            maxWidth: '97%',
            ...bqInputBorder,
            lineHeight: '26px',
            backgroundColor: 'white',
            '&:hover': {
                border: 'solid thin rgb(0,0,0)',
            }
        },
        bqInputValueBorderless: {
            background: 'url(\'/icons/icons-arrow-down.svg\') no-repeat',
            backgroundSize: '24px 24px',
            backgroundPositionX: '70%',
            backgroundPositionY: '50%',
            fontSize: normalTextSize,
            color: textColor,
            width: '100%',
            lineHeight: '26px',
            '&:hover': {
                border: 'none',
            }
        },
        bqInputValueReadOnly: {

        },
        bqInputValueError: {
            border: 'solid thin red',
        },
        valueLabel: {
            fontSize: normalTextSize,
            color: textColor,
            marginTop: '8px',
            padding: '12px 9px 9px 0px',
            borderRadius: '3px',
        },
        dropdownInputStyle: {
            fontSize: normalTextSize,
            width: '100%',
            color: textColor,
            border: 'none',
            padding: '12px 9px 9px 12px',
            borderRadius: '3px',
            resize: 'none',
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        editableListNumberingColumn: {
            verticalAlign: 'middle',
            fontSize: '11px',
            width: '22px',
            maxWidth: '22px',
        },
        editableListNumbering: {
            backgroundColor: '#AAAAAA',
            color: 'white',
            borderRadius: '100px',
            paddingTop: '2px',
            textAlign: 'center',
            width: '22px',
            maxWidth: '22px',
            lineHeight: '20px'
        },
        editableListDataColumn: {
            paddingLeft: '4px',
            paddingRight: '4px',
        },
        action: {
            paddingTop: '0.5em',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        toolbarContainer: {
            position: 'fixed',
            background: 'white',
            left: '0px',
            top: 0,
            right: 0,
            zIndex: 10000,
            boxShadow: '0 2px 14px 0 rgba(113, 113, 113, 0.14)',
            height: '136px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
        },
        toolbarFormButtonsContainer: {
            width: '100%',
            textAlign: 'right',
        },
        toolbarMinimumCell: {
            padding: '0px 16px',
            minWidth: '64px'
        },
        toolbarContentCell: {
            width: '100%',
            textAlign: 'left',
            fontSize: '24px',
            padding: 0,
        },
        backButton: {
            cursor: 'pointer',
            margin: 0,
            padding: '24px 16px 5px 16px',

            borderRadius: '3px',
            border: 'none',
            display: 'inline',
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.1)',
            }
        },
        addButton: {
            ...iconButtonStyle('icons-add'),
            marginLeft: '28px',
            marginTop: '-24px',
        },
        editButton: {
            ...iconButtonStyle('icons-edit'),
        },
        removeButton: {
            ...iconButtonStyle('icons-remove')
        },
        upButton: {
            ...iconButtonStyle('icons-sort-up'),
            // transform: 'translateY(0.5px)',
        },
        downButton: {
            ...iconButtonStyle('icons-sort-up'),
            transform: 'rotateX(180deg)',
        },
        saveButton: {
            ...iconButtonStyle('icons-save'),
        },
        copyButton: {
            ...iconButtonStyle('icons-copy'),
        },
        hiddenEyeButton: {
            ...iconButtonStyle('icons-hide'),
        },
        eyeButton: {
            ...iconButtonStyle('icons-see'),
        },
        sendButton: {
            ...iconButtonStyle('icons-send'),
        },
        packageReceived: {
            ...iconButtonStyle('icons-package-received', 20)
        },
        logoutButton: {
            ...iconButtonStyle('icons-logout'),
            position: 'fixed',
            bottom: 34,
            left: 168,
            zIndex: 100002
        },
        thinButton: {
            //margin: '0px -8px !important',
            height: '52px',
            width: '14px !important',
            maxWidth: '14px !important'
        },
        userInfoSection: {
            color: 'white',
            position: 'fixed',
            zIndex: 10001,
            width: 168,
            maxWidth: 168,
            height: 96,
            bottom: 0,
            verticalAlign: 'middle',
            alignItems: 'center',
            textAlign: 'center',
            overflow: 'hidden'
        },
        centerContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%'
        },
        sideBySideTable: {
            width: '100%',
        },
        sideBySideTableColumn: {
            verticalAlign: 'top'
        },
        dropdownContainer: {
            ...bqInputBorder,
            background: 'white',
            zIndex: 1000000,
            position: 'absolute',
            left: 0,
            right: 0,
            minWidth: '96px',
            maxHeight: '256px',
            overflowY: 'auto',
            overflowX: 'hidden',
            boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.2)',
        },
        dropdownItem: {
            cursor: 'pointer',
            minWidth: '60px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderBottom: 'solid thin rgba(0, 0, 0, 0.1)',
            lineHeight: '48px',
            position: 'relative',
            '&:hover': {
                background: 'rgb(240, 240, 240)',
            }
        },
        dropdownDefaultIcon: {
            border: `solid thin ${bqBlue}`,
            fontSize: '12px',
            color: bqBlue,
            position: 'absolute',
            padding: '4px 4px 0px 4px',
            lineHeight: '14px',
            top: '13px',
            right: '4px',
            borderRadius: '4px'
        },
        dropdownArrowIcon: {
            cursor: 'pointer',
            fontSize: '24px',
            position: 'absolute',
            left: '48px',
            top: '2px'
        },
        dropdownClearIcon: {
            cursor: 'pointer',
            fontSize: '16px !important',
            width: '16px',
            color: 'rgba(0,0,0,0.5)',
            position: 'absolute',
            right: '5%',
            top: '10px'
        },
        bqTableRow: {
            borderBottom: 'solid thin rgba(0, 0, 0, 0.2)',
        },
        exerciseForPatientContent: {
            verticalAlign: 'top',
            marginTop: '-8px',
            marginBottom: '-8px',
            paddingLeft: '8px'
        },
        buttonReset: {
            boxShadow: 'none',
            textAlign: 'center',
            width: '84px',
            height: 'auto',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '5px',
            paddingBottom: '6px',
            marginLeft: '48px',
            fontSize: '14px'
        }
    }),
    { name: 'BQClasses' }
);

const iconButtonStyle = (iconName, size) => {
    size = size || 16
    return {
        display: 'inline-block',
        cursor: 'pointer',
        margin: 0,
        marginTop: '6px',
        height: '32px',
        minWidth: '32px',
        borderRadius: '3px',
        border: 'none',
        backgroundSize: `${size}px ${size}px`,
        backgroundPosition: '8px',
        paddingLeft: '32px !important',
        background: `url('./icons/${iconName}.svg') no-repeat 8px 8px`,
        '&:hover': {
            backgroundSize: `${size + 2}px ${size + 2}px`,
            backgroundPosition: '8px',
            paddingLeft: '32px !important',
            background: `url('./icons/${iconName}-hover.svg') no-repeat 8px 8px`,
        }
    }
}