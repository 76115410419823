import { useNotify } from 'ra-core'
import { signOut } from '@aws-amplify/auth';
import { clearBQAuthData } from './bq-auth';


export const charCheck = '✓'
export const charBallot = '✗'

export const bqCreateRedirection = 'list'
export const bqEditRedirection = 'list'
export const bqRowClickAction = 'edit'
export const bqMutationMode = 'pessimistic'

export const choicesFromArray = (array) => array ? array.map((item) => ({ id: item.replace(/\s/g, '_').toUpperCase(), name: item })) : []
export const choicesFromObject = (obj) => obj ? Object.keys(obj).map(key => ({ id: obj[key], name: key })) : []

export const editorGlobalProps = () => {
    const notify = useNotify();
    return { mutationMode: bqMutationMode, undoable: false, onFailure: (e) => notify('Sorry, could not save data', 'error') }
}

const maxUserInactivityTime = 60000 * 20
let lastUserActivityTime = new Date().getTime();
let userInactivityChecker = null
let resetSessionTimeoutMutex = false

export const resetSessionTimeout = () => {
    // if (resetSessionTimeoutMutex) {
    //     return
    // }
    // resetSessionTimeoutMutex = true
    // lastUserActivityTime = new Date().getTime()
    // userInactivityChecker && clearInterval(userInactivityChecker)
    // userInactivityChecker = setInterval(async () => {
    //     const now = new Date().getTime();
    //     const timeSinceLastUserActivity = now - (lastUserActivityTime || 0)
    //     if (timeSinceLastUserActivity >= maxUserInactivityTime) {
    //         await signOut()
    //         clearBQAuthData()
    //         location.reload()
    //     }
    // }, 500)
    // resetSessionTimeoutMutex = false
}