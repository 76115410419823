import * as React from 'react';
import { validate } from '../utils/validations';
import { BQDropDown } from './Generic/bq-input';
import { useBQGetList } from './Generic/hooks';
let ouItems = []

export const setOU = (data) => {
    const ouItem = ouItems.find(item => item.id === data.organizationalUnitId);
    if (ouItem) {
        data.organizationalUnit = ouItem.organizationalUnit;
    }
}

export const OUSelectInput = ({
    label,
    source,
    onChange: baseOnChange,
    defaultValue,
    useNameAsValue,
    readOnly,
    filterObject,
    setFilterObject,
    notRequired,
}) => {
    ouItems = []
    const listData = useBQGetList({ resource: 'organizationalUnits', pagination: { page: 1, perPage: 1000 } });
    listData?.data && ouItems.push(...listData.data?.map(item => ({ name: item.organizationalUnit, id: item.id })))
    return <BQDropDown
        label={label || 'Clinic'}
        source={source || 'organizationalUnitId'}
        loading={listData.isLoading}
        choices={ouItems}
        validate={!notRequired ? validate : undefined}
        useNameAsValue={useNameAsValue}
        defaultValue={defaultValue}
        readOnly={readOnly}
        onChange={({ selection }) => {
            if (filterObject) {
                filterObject[source] = selection.name
                if (setFilterObject) {
                    setFilterObject({ ...filterObject })
                }
            }
            baseOnChange?.({ source, selection })
        }}
    />
}